import { useEffect, useState } from "react";
import "./Company.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setMessageError } from "../../../../redux/actions";

export default function Company() {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);

  const dispatch = useDispatch();
  const [vendors, setVendors] = useState([]);
  const [companyForm, setCompanyForm] = useState(null);

  useEffect(() => {
    loadCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCompany = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/company?companyName=" +
          encodeURIComponent( user.companyName ),
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCompanyForm(response.data.company);
      setVendors(response.data.vendors);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  const updateCompanyForm = (e) => {
    setCompanyForm({ ...companyForm, [e.target.name]: e.target.value });
  };

  const submitUserForm = (e) => {
    e.preventDefault();

    updateCompany();
  };

  const updateCompany = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          "/company?companyName=" +
          encodeURIComponent( user.companyName ),
        companyForm,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setMessage(null));
  }, [dispatch]);

  return (
    <div className="contentbar">
      <div className="row company-form-row">
        {companyForm && (
          <form
            className="needs-validation col-lg-12 update-user-form"
            onSubmit={submitUserForm}
          >
            <h3 className="mb-5">Company Details</h3>
            <div className="company-form-group">
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    className={`form-control`}
                    id="companyName"
                    name="companyName"
                    placeholder="Company name"
                    value={companyForm.companyName}
                    onChange={updateCompanyForm}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="text"
                    className={`form-control `}
                    id="phone"
                    name="phone"
                    placeholder="Phone Number"
                    value={companyForm.phone}
                    onChange={updateCompanyForm}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="industry">Industry</label>
                  <input
                    type="text"
                    className={`form-control`}
                    id="industry"
                    name="industry"
                    placeholder="Industry"
                    value={companyForm.industry}
                    onChange={updateCompanyForm}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="branch">Branch</label>
                  <input
                    type="text"
                    className={`form-control`}
                    id="branch"
                    name="branch"
                    placeholder="Branch"
                    value={companyForm.branch}
                    onChange={updateCompanyForm}
                  />
                </div>
              </div>
            </div>
            <div className="company-form-group">
              <div className="form-row">
                <div className="col-lg-12 mb-3">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className={`form-control `}
                    id="address"
                    name="address"
                    placeholder="Address"
                    value={companyForm.address}
                    onChange={updateCompanyForm}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="address2">Address 2</label>
                  <input
                    type="text"
                    className={`form-control`}
                    id="address2"
                    name="address2"
                    placeholder="Apartment, studio, floor"
                    value={companyForm.address2}
                    onChange={updateCompanyForm}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    className={`form-control`}
                    id="city"
                    name="city"
                    placeholder="City"
                    value={companyForm.city}
                    onChange={updateCompanyForm}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="state">State / Province</label>
                  <input
                    type="text"
                    className={`form-control`}
                    id="state"
                    name="state"
                    placeholder="State / Province"
                    value={companyForm.state}
                    onChange={updateCompanyForm}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="zip">Zip Code</label>
                  <input
                    type="text"
                    className={`form-control `}
                    id="zip"
                    name="zip"
                    placeholder="Zip code"
                    value={companyForm.zip}
                    onChange={updateCompanyForm}
                  />
                </div>
              </div>
            </div>
            <div className="form-row form-center-button">
              <button className="btn btn-primary" type="submit">
                Save Changes
              </button>
            </div>
            {message && (
              <div
                className={`alert ${
                  message.status === "success"
                    ? "alert-success"
                    : "alert-danger"
                } alert-dismissible fade show mt-3`}
                role="alert"
              >
                {message.message}
                <button
                  type="button"
                  className="close"
                  onClick={() => dispatch(setMessage(null))}
                >
                  <span>&times;</span>
                </button>
              </div>
            )}
          </form>
        )}
      </div>

      <div className="row">
        <div className="col-lg-12 dashboard-bg  update-user-form">
          <h3 className="mb-4">Registered Users</h3>
          <div className="table-responsive">
            <table className="table ">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Full Name</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                {vendors &&
                  vendors.map((vendor, i) => (
                    <tr key={i}>
                      <td>
                        {vendor.firstName} {vendor.lastname}
                      </td>
                      <td>{vendor.email}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
