import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessageError } from "../../../../../redux/actions";

import "./UpdateVendorSelection.css";

export default function UpdateVendorSelection() {
  const user = useSelector((state) => state.user);

  const [companies, setCompanies] = useState(null);
  const [companyForm, setCompanyForm] = useState("");

  const [showData, setShowData] = useState([]);
  const [content, setContent] = useState(null);

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const dispatch = useDispatch();

  const tableHeader = [
    { title: "Ad Type", key: "title" },
    { title: "Option", key: "option" },
    { title: "Status", key: "status" },
  ];

  useEffect(() => {
    if (user) {
      loadCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadCompanies = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/companies",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.data.length > 0) {
        const companiesResponse = response.data
          .map((company) => company.companyName)
          .sort();
        setCompanies(companiesResponse);
        setCompanyForm(companiesResponse[0]);
      }
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  const searchCompany = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }

      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/company/package&selection/admin?companyName=" +
          encodeURIComponent( companyForm ),
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setContent(response.data);
    } catch (err) {
      if (err.response.data.message) {
        setContent([]);
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  const toggleEditOption = (uuid) => {
    const updatedContent = showData.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          editDisabled: !item.editDisabled,
          option: item.originalOption,
          adOptionId: item.originalOptionId,
        };
      } else {
        return item;
      }
    });
    setShowData(updatedContent);
  };

  const saveOption = async (material) => {
    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          "/company/package&selection/admin?companyName=" +
          encodeURIComponent( companyForm ),
        material,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      searchCompany();
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  const setNewOption = (value, material) => {
    const newData = showData.map((info) => {
      if (info.uuid === material.uuid) {
        const newOptionId = info.allOptions.filter(
          (opt) => opt.option === value
        );

        return {
          ...info,
          adOptionId: newOptionId[0].id,
          option: value,
        };
      } else {
        return info;
      }
    });
    setShowData(newData);
  };

  useEffect(() => {
    let selectedData = [...showData];

    if (sortBy) {
      if (sortDirection === "asc") {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
      } else {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1));
      }
    }
    setShowData(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (content?.length > 0) {
      let newContent = content.map((item) => ({
        ...item,
        status: item.addedAt ? "Uploaded" : "Not Uploaded",
        editDisabled: true,
        originalOption: item.option,
        originalOptionId: item.adOptionId,
      }));

      setShowData(newContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <div className="contentbar">
      <div className="row company-form-row">
        {companies && (
          <form
            className="needs-validation col-lg-12 update-user-form"
            onSubmit={searchCompany}
          >
            <h3 className="mb-5">Update Vendor Package Selections</h3>
            <div className="form-group col-md-6">
              <label htmlFor="categoryId">Company</label>
              <select
                className="form-control"
                id="category"
                name="category"
                onChange={(e) => setCompanyForm(e.target.value)}
                value={companyForm}
              >
                {companies &&
                  companies.map((company) => (
                    <option key={company}>{company}</option>
                  ))}
              </select>
            </div>
            <div className="form-row form-center-button col-md-6">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </div>
          </form>
        )}
      </div>
      {content && (
        <>
          {content.length === 0 && (
            <div className="row dashboard-bg mt-5 vendor-selection">
              <div className="col-lg-12">
                <h4 className="text-center mb-3 mt-3">
                  {companyForm} has either yet to make selections or does not have a package assigned to them.
                </h4>
              </div>
            </div>
          )}
          {content.length > 0 && (
            <div className="row dashboard-bg vendor-selection">
              <div className="table-responsive">
                <div
                  id="default-datatable_wrapper"
                  className="dataTables_wrapper container-fluid dt-bootstrap4"
                >
                  <div className="row">
                    <div className="col-sm-12 table-scroll">
                      <table
                        id="default-datatable"
                        className="display table table-striped table-bordered dataTable dtr-inline"
                        role="grid"
                        style={{ minWidth: "700px" }}
                      >
                        <thead>
                          <tr>
                            {tableHeader.map((header) => (
                              <th
                                className={
                                  !header.key
                                    ? null
                                    : sortBy !== header.key
                                    ? "sorting"
                                    : sortDirection === "asc"
                                    ? "sorting_asc"
                                    : "sorting_desc"
                                }
                                key={header.title}
                                onClick={() => {
                                  if (header.key) {
                                    setSortBy(header.key);
                                    setSortDirection(
                                      sortDirection === "asc" ? "desc" : "asc"
                                    );
                                  }
                                }}
                              >
                                {header.title}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {showData.length > 0 &&
                            showData.map((info, i) => (
                              <tr key={info.uuid}>
                                <td>{info.title}</td>
                                <td>
                                  <select
                                    className="form-control"
                                    id="category"
                                    name="category"
                                    onChange={(e) =>
                                      setNewOption(e.target.value, info)
                                    }
                                    value={info.option}
                                    disabled={info.editDisabled}
                                  >
                                    {info.allOptions &&
                                      info.allOptions.map((opt) => (
                                        <option key={info.uuid + opt.option}>
                                          {opt.option}
                                        </option>
                                      ))}
                                  </select>
                                  <div className="icons-wrapper mt-2">
                                    {info.editDisabled ? (
                                      <div
                                        className="text-primary cursor-pointer"
                                        onClick={() =>
                                          toggleEditOption(info.uuid)
                                        }
                                      >
                                        <i className="feather icon-edit mr-2"></i>
                                        edit
                                      </div>
                                    ) : (
                                      <>
                                        <div
                                          className="text-danger cursor-pointer"
                                          onClick={() =>
                                            toggleEditOption(info.uuid)
                                          }
                                        >
                                          Cancel
                                        </div>
                                        <div
                                          className="text-success cursor-pointer"
                                          onClick={() => saveOption(info)}
                                        >
                                          <i className="feather icon-save mr-2"></i>
                                          Save
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td
                                  className={
                                    info.status === "Uploaded"
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {info.status}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
