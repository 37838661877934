import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableAdmin from "../../../../components/Tables/TableAdmin";
import { setMessageError } from "../../../../redux/actions";
import "./CastleStaff.css";

export default function CastleStaff() {
  const user = useSelector((state) => state.user);
  const [castleStaff, setCastleStaff] = useState([]);
  const dispatch = useDispatch();

  const loadCastleStaff = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/Castle",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setCastleStaff(response.data);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    loadCastleStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contentbar">
      <div className="row">
        <TableAdmin
          tableType={"Castle Staff"}
          data={castleStaff}
          loadUsers={loadCastleStaff}
        />
      </div>
    </div>
  );
}
