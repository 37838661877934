import { useLocation, useParams } from "react-router-dom";
import "./BreadCrumbBar.css";

export default function BreadCrumbBar() {
  const routeLocation = useLocation().pathname.split("/");
  const userUuid = useParams()?.userUuid;
  const uuid = useParams()?.uuid;

  return (
    <div className="breadcrumbbar">
      <div className="row align-items-center">
        <div className="col-md-8 col-lg-8">
          <h4 className="page-title breadcrumbbar-capitalize">
            {routeLocation[2]}
          </h4>
          <div className="breadcrumb-list">
            {routeLocation[2] !== "admin" &&
              routeLocation.length > 3 &&
              userUuid !== routeLocation[3] && (
                <ol className="breadcrumb">
                  <li
                    className={`breadcrumb-item breadcrumbbar-capitalize ${
                      routeLocation.length > 4 ? null : "active"
                    }`}
                  >
                    <div>{routeLocation[3]}</div>
                  </li>
                  {routeLocation.length > 4 &&
                    userUuid !== routeLocation[4] &&
                    uuid !== routeLocation[4] && (
                      <li className="breadcrumb-item breadcrumbbar-capitalize active">
                        <div>{routeLocation[4]}</div>
                      </li>
                    )}
                </ol>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
