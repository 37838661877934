import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import CastleLogo from "../../assets/logos/elite-marketing.svg";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [emailValidation, setEmailValidation] = useState(true);

  const [resetMessage, setResetMessage] = useState({
    status: null,
    message: "",
  });

  const updateEmail = (e) => {
    setEmailValidation(e.target.value === "" ? false : true);

    setEmail(e.target.value);
  };

  const requestReset = async (e) => {
    e.preventDefault();

    if (email === "") {
      setEmailValidation(false);
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reset-password`, {
        email,
      });

      setResetMessage({
        status: "success",
        message:
          "Step 2. Check your email address and follow the steps to access your account.",
      });
    } catch (err) {
      if (err.response.data.message) {
        setResetMessage({
          status: "error",
          message: err.response.data.message,
        });
      } else {
        setResetMessage({
          status: "error",
          message: err.response.status + " - " + err.response.statusText,
        });
      }
    }
  };

  return (
    <div id="containerbar" className="containerbar authenticate-bg">
      <div className="container">
        <div className="auth-box login-box">
          <div className="row no-gutters align-items-center justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="auth-box-right">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={requestReset}>
                      <div className="form-head">
                        <a href="index.html" className="logo">
                          <img
                            src={CastleLogo}
                            className="img-fluid login-logo"
                            alt="logo"
                          />
                        </a>
                      </div>
                      <h4 className="text-primary my-4">Reset Password</h4>
                      <p className="mb-4" style={{ textAlign: "start" }}>
                        Step 1. Enter your email address!
                      </p>
                      <div className="form-group">
                        <input
                          type="email"
                          className={`form-control ${
                            emailValidation ? null : "is-invalid"
                          }`}
                          id="email"
                          name="email"
                          value={email}
                          onChange={updateEmail}
                          disabled={
                            resetMessage.status === "success" ? true : false
                          }
                        />
                        {!emailValidation && (
                          <div className="invalid-feedback">
                            Please provide the email.
                          </div>
                        )}
                      </div>
                      {resetMessage.status !== "success" && (
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block font-18"
                        >
                          Request Reset
                        </button>
                      )}
                    </form>
                    {resetMessage.status === "error" && (
                      <div
                        className={`alert alert-danger
                         alert-dismissible fade show mt-3`}
                        role="alert"
                      >
                        {resetMessage.message}
                        <button
                          type="button"
                          className="close"
                          onClick={() =>
                            setResetMessage({
                              status: null,
                              message: "",
                            })
                          }
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                    )}
                    {resetMessage.status === "success" && (
                      <p className="mb-4 mt-4" style={{ textAlign: "start" }}>
                        {resetMessage.message}
                      </p>
                    )}
                    <p className="mb-0 mt-5">
                      Remember Password?{" "}
                      <Link to="/login" className="ml-1">
                        Log in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
