import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import CastleMinLogo from "../../assets/logos/castle-min.svg";
import EliteLogo from "../../assets/logos/elite-marketing.svg";
import "./LeftSideBar.css";

export default function LeftSideBar() {
  const user = useSelector((state) => state.user);

  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedSubmenu, setSelectedSubmenu] = useState("");

  const setMenu = (menu) => {
    setSelectedSubmenu("");
    if (menu === selectedMenu) {
      setSelectedMenu("");
    } else {
      setSelectedMenu(menu);
    }
  };
  const setSubmenu = (submenu) => {
    if (submenu === selectedSubmenu) {
      setSelectedSubmenu("");
    } else {
      setSelectedSubmenu(submenu);
    }
  };

  return (
    <div className="leftbar">
      <div className="sidebar">
        <div className="logobar">
          <Link to="/user/dashboard" className="logo logo-large">
            <img
              src={EliteLogo}
              className="img-fluid"
              alt="logo"
              style={{ height: "2.7rem" }}
            />
          </Link>
          <Link to="/user/dashboard" className="logo logo-small">
            <img
              src={CastleMinLogo}
              className="img-fluid"
              alt="logo"
              style={{ height: "2.7rem" }}
            />
          </Link>
        </div>

        <div className="navigationbar">
          <ul className="vertical-menu">
            <li className={selectedMenu === "Dashboard" ? "active" : null}>
              <Link to="/user/dashboard" onClick={() => setMenu("Dashboard")}>
                <img
                  src="/orbiter-assets/images/svg-icon/dashboard.svg"
                  className="img-fluid"
                  alt="dashboard"
                />
                <span>Dashboard</span>
              </Link>
              <ul className="vertical-submenu"></ul>
            </li>
            {user.role === "Administrator" && (
              <li className={selectedMenu === "Admin" ? "active" : null}>
                <div onClick={() => setMenu("Admin")}>
                  <img
                    src="/orbiter-assets/images/svg-icon/basic.svg"
                    className="img-fluid"
                    alt="basic"
                  />
                  <span>Site Admin</span>
                  <i className="feather icon-chevron-right pull-right"></i>
                </div>
                <ul
                  className={`vertical-submenu ${
                    selectedMenu === "Admin" ? "menu-open" : null
                  }`}
                >
                  <li>
                    <NavLink to="/user/admin/administrators">
                      Administrators
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/user/admin/castle-staff">
                      Castle Staff
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
            {user.role !== "Vendor" && (
              <li className={selectedMenu === "Castle" ? "active" : null}>
                <div onClick={() => setMenu("Castle")}>
                  <img
                    src="/orbiter-assets/images/svg-icon/tables.svg"
                    className="img-fluid"
                    alt="advanced"
                  />
                  <span>Castle Admin</span>
                  <i className="feather icon-chevron-right pull-right"></i>
                </div>
                <ul
                  className={`vertical-submenu ${
                    selectedMenu === "Castle" ? "menu-open" : null
                  }`}
                >
                  <li
                    className={selectedSubmenu === "Vendors" ? "active" : null}
                  >
                    <div onClick={() => setSubmenu("Vendors")}>
                      Vendors
                      <i className="feather icon-chevron-right pull-right"></i>
                    </div>
                    <ul
                      className={`vertical-submenu ${
                        selectedSubmenu === "Vendors" ? "menu-open" : null
                      }`}
                    >
                      <li>
                        <NavLink to="/user/castle/vendors/view">
                          View All
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/user/castle/vendors/uploads">
                          Uploads
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/user/castle/vendors/selections">
                          Selections
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="mb-2">
                    <NavLink to="/user/castle/vendors">Vendors</NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink to="/user/castle/uploads">Uploads</NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink to="/user/castle/vendor-selections">
                      Vendor Selections
                    </NavLink>
                  </li> */}

                  <li
                    className={selectedSubmenu === "Packages" ? "active" : null}
                  >
                    <div onClick={() => setSubmenu("Packages")}>
                      Packages
                      <i className="feather icon-chevron-right pull-right"></i>
                    </div>
                    <ul
                      className={`vertical-submenu ${
                        selectedSubmenu === "Packages" ? "menu-open" : null
                      }`}
                    >
                      <li>
                        <NavLink to="/user/castle/packages/view">
                          View All
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/user/castle/packages/add">
                          Add Package
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className={selectedSubmenu === "Ads" ? "active" : null}>
                    <div onClick={() => setSubmenu("Ads")}>
                      Manage Ads
                      <i className="feather icon-chevron-right pull-right"></i>
                    </div>
                    <ul
                      className={`vertical-submenu ${
                        selectedSubmenu === "Ads" ? "menu-open" : null
                      }`}
                    >
                      <li>
                        <NavLink to="/user/castle/ads/types">Ad Types</NavLink>
                      </li>
                      <li>
                        <NavLink to="/user/castle/ads/due-dates">
                          Ad Due Dates
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="mb-2">
                    <NavLink to="/user/castle/reports">Reports</NavLink>
                  </li>
                </ul>
              </li>
            )}
            <li className={selectedMenu === "Vendor" ? "active" : null}>
              <div onClick={() => setMenu("Vendor")}>
                <img
                  src="/orbiter-assets/images/svg-icon/ecommerce.svg"
                  className="img-fluid"
                  alt="apps"
                />
                <span>Vendor</span>
                <i className="feather icon-chevron-right pull-right"></i>
              </div>
              <ul
                className={`vertical-submenu ${
                  selectedMenu === "Vendor" ? "menu-open" : null
                }`}
              >
                <li>
                  <NavLink to="/user/vendor/package">My Package</NavLink>
                </li>
                <li>
                  <NavLink to="/user/vendor/company">My Company</NavLink>
                </li>
                <li>
                  <NavLink to={"/user/update-user/" + user.uuid}>
                    Update User Info
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user/vendor/artwork&material">
                    Artwork & Material
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
