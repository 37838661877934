import { Outlet } from "react-router-dom";
import BreadCrumbBar from "../../components/BreadCrumbBar";
import LeftSideBar from "../../components/LeftSideBar";
import TopBar from "../../components/TopBar";

export default function UserLayout() {
  return (
    <div id="containerbar">
      <LeftSideBar />
      <div className="rightbar">
        <TopBar />
        <BreadCrumbBar />
        <Outlet />
      </div>
      <div className="footerbar mt-5">
        <footer className="footer">
          <p className="mb-0">© 2022 Castle - All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
}
