import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import CastleLogo from "../../assets/logos/elite-marketing.svg";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/thunks";
import { setMessage } from "../../redux/actions";

export default function Login() {
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [loginFormValidation, setLoginFormValidation] = useState({
    email: true,
    password: true,
  });

  const loginMessage = useSelector((state) => state.messageInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setMessage(null));
  }, [dispatch]);

  const updateLoginForm = (e) => {
    setLoginFormValidation({
      ...loginFormValidation,
      [e.target.name]: e.target.value === "" ? false : true,
    });

    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const submitLogin = (e) => {
    e.preventDefault();

    if (loginForm.email === "" || loginForm.password === "") {
      setLoginFormValidation({
        email: loginForm.email === "" ? false : true,
        password: loginForm.password === "" ? false : true,
      });
      return;
    }

    dispatch(login(loginForm, navigate));
  };

  return (
    <div id="containerbar" className="containerbar authenticate-bg">
      <div className="container">
        <div className="auth-box login-box">
          <div className="row no-gutters align-items-center justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="auth-box-right">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={submitLogin}>
                      <div className="form-head">
                        <a href="index.html" className="logo">
                          <img
                            src={CastleLogo}
                            className="img-fluid login-logo"
                            alt="logo"
                          />
                        </a>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email" className="login-label">
                          Username
                        </label>
                        <input
                          type="email"
                          className={`form-control ${
                            loginFormValidation.email ? null : "is-invalid"
                          }`}
                          id="email"
                          name="email"
                          value={loginForm.email}
                          onChange={updateLoginForm}
                        />
                        {!loginFormValidation.email && (
                          <div className="invalid-feedback">
                            Please provide the username.
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="login-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className={`form-control ${
                            loginFormValidation.password ? null : "is-invalid"
                          }`}
                          id="password"
                          name="password"
                          value={loginForm.password}
                          onChange={updateLoginForm}
                        />
                        {!loginFormValidation.password && (
                          <div className="invalid-feedback">
                            Please provide the password.
                          </div>
                        )}
                      </div>
                      <div className="form-row mb-3">
                        <div className="col-sm-12">
                          <div className="forgot-psw">
                            <Link to="/reset-password" className="font-14">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block font-18"
                      >
                        Login to Dashboard
                      </button>
                    </form>
                    {loginMessage && (
                      <div
                        className={`alert ${
                          loginMessage.status === "success"
                            ? "alert-success"
                            : "alert-danger"
                        } alert-dismissible fade show mt-3`}
                        role="alert"
                      >
                        {loginMessage.message}
                        <button
                          type="button"
                          className="close"
                          onClick={() => dispatch(setMessage(null))}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
