// import actions
import {
  LOAD_NOTIFICATIONS,
  LOAD_USER,
  LOGIN_USER,
  LOGOUT_USER,
  SET_MESSAGE,
  SET_MESSAGE_ERROR,
} from "./actions";

const initialState = null;

export const user = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return payload;
    case LOGIN_USER:
      return payload;
    case LOGOUT_USER:
      localStorage.removeItem("user");
      return null;
    default:
      return state;
  }
};

export const messageInfo = (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return payload;
    default:
      return state;
  }
};

export const messageError = (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE_ERROR:
      return payload;
    default:
      return state;
  }
};

export const notifications = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_NOTIFICATIONS:
      return payload;
    default:
      return state;
  }
};
