import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setMessage } from "../../../redux/actions";
import { formatDate } from "../../../utils/formatDate";
import AddVendorUserModal from "../../Modals/AddVendorUserModal";
import DeleteUserModal from "../../Modals/DeleteUserModal";
import "./TableVendor.css";
import AddVendorCompanyModal from "../../Modals/AddVendorCompanyModal";

export default function TableVendor({ data, tableType, loadUsers, companies }) {
  const [numEntries, setNumEntries] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(1);
  const [deletedUser, setDeleteUser] = useState(null);

  const dispatch = useDispatch();

  const [showData, setShowData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const tableHeader = [
    { title: "Company", key: "companyName" },
    { title: "First Name", key: "firstName" },
    { title: "Last Name", key: "lastName" },
    { title: "Email", key: "email" },
    { title: "Package Assigned", key: "packageName" },
    { title: "Package Year", key: "year" },
    { title: "Selections", key: "hasSelectedOptions" },
    { title: "Action" },
  ];

  useEffect(() => {
    let selectedData = [...data];

    if (searchTerm) {
      selectedData = selectedData.filter(
        (info) =>
          info.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.packageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(info.year).toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    }

    if (sortBy) {
      if (sortDirection === "asc") {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
      } else {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1));
      }
    }

    setTotalEntries(selectedData.length);
    setTotalPages(Math.ceil(selectedData.length / numEntries));

    selectedData = selectedData.slice(
      (currentPage - 1) * numEntries,
      currentPage * numEntries
    );

    setShowData(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, numEntries, sortBy, sortDirection, searchTerm]);

  useEffect(() => {
    if (totalPages < currentPage) setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  useEffect(() => {
    if (data.length > 0) {
      let selectedData = [...data];
      //selectedData.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
      selectedData = selectedData.slice(
        (currentPage - 1) * numEntries,
        numEntries
      );

      setTotalEntries(data.length);
      setShowData(selectedData);
      setTotalPages(Math.ceil(data.length / numEntries));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="col-lg-12 dashboard-bg">
      <div className="card m-b-30">
        <div className="card-header">
          <h5 className="card-title">Registered {tableType}s</h5>
        </div>
        <div className="card-body table-body">
          <h6 className="card-subtitle">Manage users and data</h6>
          <div>
            <button
              type="button"
              className="btn btn-primary model-animation-btn"
              data-animation="slideInDown"
              data-toggle="modal"
              data-target="#addVendorCompanyModal"
              onClick={() => dispatch(setMessage(null))}
            >
              <i className="feather icon-plus mr-2"></i> Add Company
            </button>
            <button
              type="button"
              className="btn btn-info model-animation-btn ml-2"
              data-animation="slideInDown"
              data-toggle="modal"
              data-target="#addVendorUserModal"
              onClick={() => dispatch(setMessage(null))}
            >
              <i className="feather icon-plus mr-2"></i> Add {tableType} User
            </button>
          </div>
        </div>
      </div>
      {data?.length > 0 && (
        <div className="table-responsive">
          <div
            id="default-datatable_wrapper"
            className="dataTables_wrapper container-fluid dt-bootstrap4"
          >
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div
                  className="dataTables_length"
                  id="default-datatable_length"
                >
                  <label>
                    Show{" "}
                    <select
                      name="default-datatable_length"
                      className="form-control form-control-sm"
                      value={numEntries}
                      onChange={(e) => setNumEntries(Number(e.target.value))}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div
                  id="default-datatable_filter"
                  className="dataTables_filter"
                >
                  <label>
                    Search:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 table-scroll">
                <table
                  id="default-datatable"
                  className="display table table-striped table-bordered dataTable dtr-inline"
                  role="grid"
                  style={{ minWidth: "700px" }}
                >
                  <thead>
                    <tr>
                      {tableHeader.map((header) => (
                        <th
                          className={
                            !header.key
                              ? null
                              : sortBy !== header.key
                              ? "sorting"
                              : sortDirection === "asc"
                              ? "sorting_asc"
                              : "sorting_desc"
                          }
                          key={header.title}
                          onClick={() => {
                            if (header.key) {
                              setSortBy(header.key);
                              setSortDirection(
                                sortDirection === "asc" ? "desc" : "asc"
                              );
                            }
                          }}
                        >
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {showData.map((info, i) => (
                      <tr key={info.email + "-" + i}>
                        <td>{info.companyName}</td>
                        <td>{info.firstName}</td>
                        <td>{info.lastName}</td>
                        <td>{info.email}</td>
                        <td
                          className={
                            info.packageName === "No Package Assigned"
                              ? "text-danger"
                              : null
                          }
                        >
                          {info.packageName}
                        </td>
                        <td>{info.year}</td>
                        <td
                          className={
                            info.hasSelectedOptions
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {info.hasSelectedOptions
                            ? "Made Selections"
                            : "No Selections"}
                        </td>
                        <td className="icons-wrapper">
                          <Link to={`/user/update-user/${info.uuid}`}>
                            <i className="fa fa-edit"></i>
                          </Link>
                          <i
                            className="fa fa-trash"
                            onClick={() => {
                              setDeleteUser(info);
                              dispatch(setMessage(null));
                            }}
                            data-animation="slideInDown"
                            data-toggle="modal"
                            data-target="#deleteUserModal"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      {tableHeader.map((header, i) => (
                        <th key={i}>{header.title}</th>
                      ))}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <div
                  className="dataTables_info"
                  id="default-datatable_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {(currentPage - 1) * numEntries + 1} to{" "}
                  {totalEntries > currentPage * numEntries
                    ? currentPage * numEntries
                    : totalEntries}{" "}
                  of {totalEntries} entries
                </div>
              </div>
              <div className="col-sm-12 col-md-7">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="default-datatable_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        currentPage === 1 ? "disabled" : null
                      }`}
                      id="default-datatable_previous"
                      onClick={() => {
                        if (currentPage !== 1) setCurrentPage(currentPage - 1);
                      }}
                    >
                      <div className="page-link">Previous</div>
                    </li>
                    {new Array(...Array(totalPages)).map((_, i) => (
                      <li
                        className={`paginate_button page-item ${
                          currentPage === i + 1 ? "active" : null
                        }`}
                        onClick={() => setCurrentPage(i + 1)}
                        key={i}
                      >
                        <div className="page-link">{i + 1}</div>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        currentPage === totalPages ? "disabled" : null
                      } `}
                      id="default-datatable_next"
                      onClick={() => {
                        if (currentPage !== totalPages)
                          setCurrentPage(currentPage + 1);
                      }}
                    >
                      <div className="page-link">Next</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AddVendorCompanyModal loadUsers={loadUsers} />
      <AddVendorUserModal
        userRole={tableType}
        loadUsers={loadUsers}
        companies={companies}
      />
      {deletedUser && (
        <DeleteUserModal
          userRole={tableType}
          deletedUser={deletedUser}
          loadUsers={loadUsers}
        />
      )}
    </div>
  );
}
