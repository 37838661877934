import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setMessage } from "../../../redux/actions";
import AddYearModal from "../../Modals/AddYearModal";
import "./TableAdDueDates.css";

export default function TableAdDueDates({ data, loadAdDueDates }) {
  const [numEntries, setNumEntries] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(1);

  const [showData, setShowData] = useState([]);

  const dispatch = useDispatch();

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const tableHeader = [{ title: "Year", key: "year" }, { title: "Action" }];

  useEffect(() => {
    let selectedData = [...data];

    if (sortBy) {
      if (sortDirection === "asc") {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
      } else {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1));
      }
    }

    setTotalEntries(selectedData.length);
    setTotalPages(Math.ceil(selectedData.length / numEntries));

    selectedData = selectedData.slice(
      (currentPage - 1) * numEntries,
      currentPage * numEntries
    );

    setShowData(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, numEntries, sortBy, sortDirection]);

  useEffect(() => {
    if (totalPages < currentPage) setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  useEffect(() => {
    if (data.length > 0) {
      let selectedData = [...data];
      selectedData.sort((a, b) => (a.year > b.year ? -1 : 1));
      selectedData = selectedData.slice(
        (currentPage - 1) * numEntries,
        numEntries
      );

      setTotalEntries(data.length);
      setShowData(selectedData);
      setTotalPages(Math.ceil(data.length / numEntries));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="col-lg-12 dashboard-bg vendor-selection">
      <div className="card m-b-30">
        <div className="card-header">
          <h5 className="card-title">Ads Due Date by Year</h5>
        </div>
        <div className="card-body table-body">
          <h6 className="card-subtitle">Manage ad due dates</h6>
          <button
            type="button"
            className="btn btn-primary model-animation-btn"
            data-animation="slideInDown"
            data-toggle="modal"
            data-target="#addYearModal"
            onClick={() => dispatch(setMessage(null))}
          >
            <i className="feather icon-plus mr-2"></i> Add Year
          </button>
        </div>
      </div>
      {data?.length > 0 && (
        <div className="table-responsive">
          <div
            id="default-datatable_wrapper"
            className="dataTables_wrapper container-fluid dt-bootstrap4"
          >
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div
                  className="dataTables_length"
                  id="default-datatable_length"
                >
                  <label>
                    Show{" "}
                    <select
                      name="default-datatable_length"
                      className="form-control form-control-sm"
                      value={numEntries}
                      onChange={(e) => setNumEntries(Number(e.target.value))}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 table-scroll">
                <table
                  id="default-datatable"
                  className="display table table-striped table-bordered dataTable dtr-inline"
                  role="grid"
                  style={{ minWidth: "700px" }}
                >
                  <thead>
                    <tr>
                      {tableHeader.map((header) => (
                        <th
                          className={
                            !header.key
                              ? null
                              : sortBy !== header.key
                              ? "sorting"
                              : sortDirection === "asc"
                              ? "sorting_asc"
                              : "sorting_desc"
                          }
                          key={header.title}
                          onClick={() => {
                            if (header.key) {
                              setSortBy(header.key);
                              setSortDirection(
                                sortDirection === "asc" ? "desc" : "asc"
                              );
                            }
                          }}
                        >
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {showData.map((info) => (
                      <tr key={info.year}>
                        <td>{info.year}</td>
                        <td>
                          <Link to={"/user/castle/ads/due-dates/" + info.year}>
                            <button
                              type="button"
                              className="btn btn-outline-primary model-animation-btn"
                            >
                              Edit Due Dates
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <div
                  className="dataTables_info"
                  id="default-datatable_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {(currentPage - 1) * numEntries + 1} to{" "}
                  {totalEntries > currentPage * numEntries
                    ? currentPage * numEntries
                    : totalEntries}{" "}
                  of {totalEntries} entries
                </div>
              </div>
              <div className="col-sm-12 col-md-7">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="default-datatable_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        currentPage === 1 ? "disabled" : null
                      }`}
                      id="default-datatable_previous"
                      onClick={() => {
                        if (currentPage !== 1) setCurrentPage(currentPage - 1);
                      }}
                    >
                      <div className="page-link">Previous</div>
                    </li>
                    {new Array(...Array(totalPages)).map((_, i) => (
                      <li
                        className={`paginate_button page-item ${
                          currentPage === i + 1 ? "active" : null
                        }`}
                        onClick={() => setCurrentPage(i + 1)}
                        key={i}
                      >
                        <div className="page-link">{i + 1}</div>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        currentPage === totalPages ? "disabled" : null
                      } `}
                      id="default-datatable_next"
                      onClick={() => {
                        if (currentPage !== totalPages)
                          setCurrentPage(currentPage + 1);
                      }}
                    >
                      <div className="page-link">Next</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AddYearModal loadAdDueDates={loadAdDueDates} />
    </div>
  );
}
