import { useEffect, useState } from "react";
import "./EditPackage.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setMessageError } from "../../../../../redux/actions";
import { useNavigate, useParams } from "react-router-dom";

export default function EditPackage() {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);

  const { uuid } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [adsForm, setAdsForm] = useState(null);
  const [adsFormValidation, setAdsFormValidation] = useState(null);
  const [inputs, setInputs] = useState(null);
  const [years, setYears] = useState([]);
  const [activeAds, setActiveAds] = useState([]);
  const [isNewPackage, setIsNewPackage] = useState(true);

  const [packageForm, setPackageForm] = useState(null);
  const [packageFormValidation, setPackageFormValidation] = useState(null);

  useEffect(() => {
    loadPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPackage = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/packages/" + uuid,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActiveAds(response.data.activeAds);
      setYears(response.data.years);
      uuid === "add" ? setIsNewPackage(true) : setIsNewPackage(false);

      setPackageForm(response.data.package);
      setPackageFormValidation({ packageName: true, price: true, year: true });
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    if (activeAds.length > 0) {
      const options = {};
      const optionsValidation = {};
      const inputsValidation = [];
      activeAds.forEach((cat) => {
        cat.adTypes.forEach((ad) => {
          // key: adType id, value: qty
          options[ad.id] = ad.qty ? ad.qty : 0;
          optionsValidation[ad.id] = true;
          inputsValidation.push(ad.id);
        });
      });

      setAdsForm(options);
      setAdsFormValidation(optionsValidation);
      setInputs(inputsValidation);
    }
  }, [activeAds]);

  const updateAdsForm = (e, id) => {
    setAdsForm({ ...adsForm, [id]: e.target.value });
    setAdsFormValidation({
      ...adsFormValidation,
      [id]: e.target.value < 0 ? false : true,
    });
  };

  const updatePackageForm = (e) => {
    setPackageForm({ ...packageForm, [e.target.name]: e.target.value });
    if (e.target.name === "price") {
      setPackageFormValidation({
        ...packageFormValidation,
        price: e.target.value === "" || e.target.value <= 0 ? false : true,
      });
    } else {
      setPackageFormValidation({
        ...packageFormValidation,
        [e.target.name]: e.target.value === "" ? false : true,
      });
    }
  };

  const submitPackageForm = (e) => {
    e.preventDefault();
    let isInvalid = false;
    const optionsValidation = {};

    inputs.forEach((input) => {
      if (adsForm[input] < 0) {
        isInvalid = true;
      }
      optionsValidation[input] = adsForm[input] < 0 ? false : true;
    });
    setAdsFormValidation(optionsValidation);

    setPackageFormValidation({
      packageName: packageForm.packageName === "" ? false : true,
      price: packageForm.price <= 0 || packageForm.price === "" ? false : true,
      year: packageForm.year === "" ? false : true,
    });

    if (
      packageForm.packageName === "" ||
      packageForm.year === "" ||
      packageForm.price <= 0 ||
      packageForm.price === ""
    ) {
      isInvalid = true;
    }
    if (isInvalid) {
      return;
    }

    const packageInfo = [];
    if (isNewPackage) {
      inputs.forEach((input) => {
        packageInfo.push({ adTypeId: input, qty: adsForm[input] });
      });
    } else {
      inputs.forEach((input) => {
        packageInfo.push({ id: input, qty: adsForm[input] });
      });
    }

    updatePackage({ ads: packageInfo, packageDetails: packageForm });
  };

  const updatePackage = async (packageInfo) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/packages/" + uuid,
        packageInfo,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));

      setTimeout(() => {
        dispatch(setMessage(null));
        navigate("/user/castle/packages/view");
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setMessage(null));
  }, [dispatch]);

  return (
    <div className="contentbar">
      <div className="row">
        <form
          className="needs-validation col-lg-12 update-user-form"
          onSubmit={submitPackageForm}
        >
          <h3 className="mb-5">
            {uuid === "add" ? "Add Package" : "Update Package"}
          </h3>
          {packageForm && (
            <div className="form-row mb-4 pb-3 due-date-wrapper">
              <div className="col-md-6 col-lg-4 mb-3">
                <label htmlFor="packageName">Package Name</label>
                <input
                  type="text"
                  className={`form-control ${
                    packageFormValidation.packageName ? null : "is-invalid"
                  }`}
                  id="packageName"
                  name="packageName"
                  placeholder="Package name"
                  value={packageForm.packageName}
                  onChange={updatePackageForm}
                />
                {!packageFormValidation.packageName && (
                  <div className="invalid-feedback">
                    Please provide the package name.
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-4 mb-3">
                <label className="text-transparent" htmlFor="price">
                  Price
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div
                      className={`input-group-text ${
                        packageFormValidation.price ? null : "price-error"
                      }`}
                    >
                      $
                    </div>
                  </div>
                  <input
                    type="number"
                    className={`form-control price-tag ${
                      packageFormValidation.price ? null : "price-error-input"
                    }`}
                    id="price"
                    name="price"
                    placeholder="Price"
                    value={packageForm.price}
                    onChange={updatePackageForm}
                  />
                  <div className="input-group-append">
                    <div
                      className={`input-group-text ${
                        packageFormValidation.price ? null : "price-error"
                      }`}
                    >
                      .00
                    </div>
                  </div>
                </div>
                {!packageFormValidation.price && (
                  <div className="invalid-feedback">
                    Please provide the price.
                  </div>
                )}
              </div>
              <div className="col-md-6  col-lg-4 mb-3">
                <div className="form-group">
                  <label htmlFor="year">Year</label>
                  <select
                    className="form-control"
                    id="year"
                    name="year"
                    onChange={updatePackageForm}
                    value={packageForm.year}
                  >
                    {years &&
                      years.map((y) => <option key={y.year}>{y.year}</option>)}
                  </select>
                </div>
              </div>
            </div>
          )}

          {adsForm && (
            <>
              {activeAds.map((info) => (
                <div
                  className="form-row mb-4 pb-3 due-date-wrapper"
                  key={info.category}
                >
                  <h5 className="col-lg-12 mb-4">
                    {info.category} ({info.description})
                  </h5>
                  {info.adTypes.map((opt) => (
                    <div className="col-sm-6 col-lg-4 p-2 mb-2" key={opt.id}>
                      <label className="mb-0">
                        {opt.title} - {opt.type}
                      </label>
                      <input
                        type="number"
                        id="autoclose-date"
                        className={`datepicker-here form-control ${
                          adsFormValidation[opt.id] ? "" : "is-invalid"
                        }`}
                        onChange={(e) => updateAdsForm(e, opt.id)}
                        value={adsForm[opt.id]}
                      />
                    </div>
                  ))}
                </div>
              ))}

              <div className="form-row form-center-button">
                <button className="btn btn-primary" type="submit">
                  {uuid === "add" ? "Add Package" : "Save Changes"}
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      {message && (
        <div
          className={`alert ${
            message.status === "success" ? "alert-success" : "alert-danger"
          } alert-dismissible fade show mt-3`}
          role="alert"
        >
          {message.message}
          <button
            type="button"
            className="close"
            onClick={() => dispatch(setMessage(null))}
          >
            <span>&times;</span>
          </button>
        </div>
      )}
    </div>
  );
}
