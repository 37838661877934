import { useSelector } from "react-redux";
import "./Dashboard.css";
import VendorDashboard from "./VendorDashboard";
import AdminCastleDashboard from "./AdminCastleDashboard";

export default function Dashboard() {
  const user = useSelector((state) => state.user);

  return (
    <div className="contentbar">
      {user.role !== "Vendor" && <AdminCastleDashboard />}
      {user.role === "Vendor" && <VendorDashboard />}
    </div>
  );
}
