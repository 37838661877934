import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessageError } from "../../../redux/actions";
import { getNotifications } from "../../../redux/thunks";
import { formatDate } from "../../../utils/formatDate";
import "./Notifications.css";

export default function Notifications() {
  const user = useSelector((state) => state.user);

  const [numEntries, setNumEntries] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(1);

  const [showData, setShowData] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const tableHeader = [
    { title: "Notification", key: "message" },
    { title: "Notification Date", key: "createdAt" },
    { title: "Action" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      loadNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadNotifications = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/notifications/all",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const newNotifications = response.data;

      setNotifications(newNotifications);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  const markAsRead = async (notification) => {
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/notifications",
        { uuid: notification.uuid },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      loadNotifications();
      dispatch(getNotifications());
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    let selectedData = notifications;

    if (sortBy) {
      if (sortDirection === "asc") {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
      } else {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1));
      }
    }

    setTotalEntries(selectedData.length);
    setTotalPages(Math.ceil(selectedData.length / numEntries));

    selectedData = selectedData.slice(
      (currentPage - 1) * numEntries,
      currentPage * numEntries
    );

    setShowData(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, numEntries, sortBy, sortDirection]);

  useEffect(() => {
    if (totalPages < currentPage) setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  useEffect(() => {
    if (notifications.length > 0) {
      let selectedData = notifications;

      selectedData = selectedData.slice(
        (currentPage - 1) * numEntries,
        numEntries
      );

      setTotalEntries(notifications.length);
      setShowData(selectedData);
      setTotalPages(Math.ceil(notifications.length / numEntries));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <div className="contentbar">
      <div className="col-lg-12  dashboard-bg">
        {notifications?.length > 0 && (
          <div className="table-responsive">
            <div
              id="default-datatable_wrapper"
              className="dataTables_wrapper container-fluid dt-bootstrap4"
            >
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div
                    className="dataTables_length"
                    id="default-datatable_length"
                  >
                    <label>
                      Show{" "}
                      <select
                        name="default-datatable_length"
                        className="form-control form-control-sm"
                        value={numEntries}
                        onChange={(e) => setNumEntries(Number(e.target.value))}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 table-scroll">
                  <table
                    id="default-datatable"
                    className="display table table-striped table-bordered dataTable dtr-inline"
                    role="grid"
                    style={{ minWidth: "700px" }}
                  >
                    <thead>
                      <tr>
                        {tableHeader.map((header) => (
                          <th
                            className={
                              !header.key
                                ? null
                                : sortBy !== header.key
                                ? "sorting"
                                : sortDirection === "asc"
                                ? "sorting_asc"
                                : "sorting_desc"
                            }
                            key={header.title}
                            onClick={() => {
                              if (header.key) {
                                setSortBy(header.key);
                                setSortDirection(
                                  sortDirection === "asc" ? "desc" : "asc"
                                );
                              }
                            }}
                          >
                            {header.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {showData.map((info, i) => (
                        <tr key={info.message + "-" + i}>
                          <td>{info.message}</td>
                          <td>{formatDate(info.createdAt)}</td>
                          <td className="icons-wrapper">
                            {info.isRead ? (
                              <div className="text-success">
                                <i className="feather icon-check mr-2"></i>Read
                              </div>
                            ) : (
                              <button
                                className="btn btn-outline-primary"
                                onClick={() => {
                                  markAsRead(info);
                                }}
                              >
                                Mark as Read
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-5">
                  <div
                    className="dataTables_info"
                    id="default-datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {(currentPage - 1) * numEntries + 1} to{" "}
                    {totalEntries > currentPage * numEntries
                      ? currentPage * numEntries
                      : totalEntries}{" "}
                    of {totalEntries} entries
                  </div>
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="default-datatable_paginate"
                  >
                    <ul className="pagination">
                      <li
                        className={`paginate_button page-item previous ${
                          currentPage === 1 ? "disabled" : null
                        }`}
                        id="default-datatable_previous"
                        onClick={() => {
                          if (currentPage !== 1)
                            setCurrentPage(currentPage - 1);
                        }}
                      >
                        <div className="page-link">Previous</div>
                      </li>
                      {new Array(...Array(totalPages)).map((_, i) => (
                        <li
                          className={`paginate_button page-item ${
                            currentPage === i + 1 ? "active" : null
                          }`}
                          onClick={() => setCurrentPage(i + 1)}
                          key={i}
                        >
                          <div className="page-link">{i + 1}</div>
                        </li>
                      ))}
                      <li
                        className={`paginate_button page-item next ${
                          currentPage === totalPages ? "disabled" : null
                        } `}
                        id="default-datatable_next"
                        onClick={() => {
                          if (currentPage !== totalPages)
                            setCurrentPage(currentPage + 1);
                        }}
                      >
                        <div className="page-link">Next</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
