import "./PageNotFound.css";
import { Link } from "react-router-dom";
import CastleLogo from "../../assets/logos/elite-marketing.svg";

export default function PageNotFound({ redirectLink }) {
  return (
    <div id="containerbar" className="containerbar authenticate-bg">
      <div className="container">
        <div className="auth-box error-box">
          <div className="row no-gutters align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6">
              <div className="text-center">
                <img
                  src={CastleLogo}
                  className="img-fluid error-logo"
                  alt="logo"
                />
                <img
                  src="/orbiter-assets/images/error/404.svg"
                  className="img-fluid error-image"
                  alt="404"
                />
                <h4 className="error-subtitle mb-4">Oops! Page not Found</h4>
                <p className="mb-4">
                  We did not find the page you are looking for. Please return to
                  previous page or visit home page.{" "}
                </p>
                <Link to={redirectLink} className="btn btn-primary font-16">
                  <i className="feather icon-home mr-2"></i>{" "}
                  {redirectLink === "/login"
                    ? "Go back to Login"
                    : "Go back to Dashboard"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
