import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessageError } from "../../../../redux/actions";
import { formatPrice } from "../../../../utils/formatPrice";
import "./MyPackage.css";

export default function MyPackage() {
  const user = useSelector((state) => state.user);
  const [vendorPackage, setVendorPackage] = useState(null);
  const [vendorPackageDetails, setVendorPackageDetails] = useState(null);

  const dispatch = useDispatch();

  const loadVendorPackage = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/vendor/package",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setVendorPackage(response.data.companyPackage);
      setVendorPackageDetails(response.data.vendorPackageDetails);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    loadVendorPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contentbar">
      <div className="row">
        {vendorPackage && (
          <div className="col-lg-12">
            <div className="card m-b-30">
              <div className="card-header">
                <h3 className="mb-4">Package Details & Selections</h3>
                <h5 className="card-title mb-2">
                  Package:{" "}
                  <span className="text-primary">
                    {vendorPackage.packageName}
                  </span>
                </h5>
                <h5 className="card-title">
                  Price:{" "}
                  <span className="text-primary">
                    {formatPrice(vendorPackage.price)}
                  </span>
                </h5>
              </div>
            </div>
            {vendorPackageDetails &&
              vendorPackageDetails.map((cat) => (
                <div
                  className="vendor-table-wrapper dashboard-bg mb-4"
                  key={cat.category}
                >
                  <h4>{cat.category}</h4>
                  <p>{cat.description}</p>
                  <div className="table-responsive mt-4">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Ad Name</th>
                          <th scope="col" className="text-center">
                            Included in Package
                          </th>
                          <th scope="col" className="text-center">
                            Selected Options
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cat.adTypes.map((ad) => (
                          <tr key={ad.title}>
                            <td>{ad.title}</td>
                            <td className="text-center">
                              {ad.qty} {ad.type}
                            </td>
                            <td className="text-center vendor-options">
                              {ad.selectedOptions.map((opt) => (
                                <p className="mb-0" key={ad.title + opt.option}>
                                  {opt.option}
                                </p>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
