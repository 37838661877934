import "./Toast.css";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setMessageError } from "../../../redux/actions";

export default function Toast() {
  const message = useSelector((state) => state.messageError);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setMessageError(null));
    }, 2000);
  }, [dispatch]);

  return (
    <div
      className="toast show"
      role="alert"
      style={{ position: "absolute", top: "30px", right: "30px" }}
    >
      <div className="toast-header">
        <i className="feather icon-alert-triangle text-danger mr-2"></i>
        <span className="toast-title mr-auto">Error</span>
      </div>
      <div className="toast-body alert-danger">{message}</div>
    </div>
  );
}
