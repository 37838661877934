import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessageError } from "../../../redux/actions";
import { formatDate } from "../../../utils/formatDate";

import DeleteUploadModal from "../../../components/Modals/DeleteUploadModal";

import "./TableUploadedMaterial.css";

export default function TablePackages({ data, loadContent }) {
  const user = useSelector((state) => state.user);

  const [numEntries, setNumEntries] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(1);
  const [deleteUpload, setDeleteUpload] = useState(null);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("all");

  const dispatch = useDispatch();

  const [showData, setShowData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const tableHeader = [
    { title: "Company", key: "companyName" },
    { title: "File Name", key: "fileName" },
    { title: "URL (if applicable)" },
    { title: "Package", key: "packageName" },
    { title: "Year", key: "year" },
    { title: "Ad Type", key: "title" },
    { title: "Option", key: "option" },
    { title: "Upload Date", key: "addedAt" },
    { title: "Due Date", key: "dueDate" },
    { title: "Status", key: "status" },
    { title: "File Actions" },
  ];

  const uploadFile = async (e, material) => {
    try {
      // max 100MB
      if (!e.target.files[0] || e.target.files[0].size > 104857600) {
        return;
      }

      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/vendor/ads/" + material.uuid,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      loadContent();
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    let selectedData = [...data];

    if (selectedCompany !== "all") {
      selectedData = selectedData.filter(
        (info) => info.companyName === selectedCompany
      );
    }

    if (searchTerm) {
      selectedData = selectedData.filter(
        (info) =>
          info.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.fileName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.url.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.packageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          info.option.toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(info.year).toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(formatDate(info.addedAt))
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          String(formatDate(info.dueDate))
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          info.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortBy) {
      if (sortDirection === "asc") {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
      } else {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1));
      }
    }

    setTotalEntries(selectedData.length);
    setTotalPages(Math.ceil(selectedData.length / numEntries));

    selectedData = selectedData.slice(
      (currentPage - 1) * numEntries,
      currentPage * numEntries
    );

    setShowData(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    numEntries,
    sortBy,
    sortDirection,
    searchTerm,
    selectedCompany,
  ]);

  useEffect(() => {
    if (totalPages < currentPage) setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  useEffect(() => {
    if (data.length > 0) {
      let selectedData = [...data];

      selectedData = selectedData.slice(
        (currentPage - 1) * numEntries,
        numEntries
      );

      setTotalEntries(data.length);
      setShowData(selectedData);
      setTotalPages(Math.ceil(data.length / numEntries));
      setSearchTerm("");
      setCompanies([...new Set(data.map((item) => item.companyName))].sort());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="col-lg-12 dashboard-bg">
      <div className="card m-b-30">
        <div className="card-header">
          <h5 className="card-title">Uploaded Material</h5>
        </div>
      </div>
      {data?.length > 0 && (
        <div className="table-responsive">
          <div
            id="default-datatable_wrapper"
            className="dataTables_wrapper container-fluid dt-bootstrap4"
          >
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <div className="row">
                  <div className="col-xl-5">
                    <div
                      className="dataTables_length"
                      id="default-datatable_length"
                    >
                      <label>
                        Show{" "}
                        <select
                          name="default-datatable_length"
                          className="form-control form-control-sm"
                          value={numEntries}
                          onChange={(e) =>
                            setNumEntries(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-7" style={{ paddingRight: 0 }}>
                    <div className="" id="default-datatable_length">
                      <label className="d-flex align-items-center">
                        Companies
                        <select
                          className="form-control select2-single ml-1"
                          value={selectedCompany}
                          onChange={(e) => setSelectedCompany(e.target.value)}
                        >
                          <option value="all">All</option>
                          {companies.length > 0 &&
                            companies.map((company) => (
                              <option value={company}>{company}</option>
                            ))}
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div
                  id="default-datatable_filter"
                  className="dataTables_filter"
                >
                  <label>
                    Search:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 table-scroll">
                <table
                  id="default-datatable"
                  className="display table table-striped table-bordered dataTable dtr-inline"
                  role="grid"
                  style={{ minWidth: "700px" }}
                >
                  <thead>
                    <tr>
                      {tableHeader.map((header) => (
                        <th
                          className={
                            !header.key
                              ? null
                              : sortBy !== header.key
                              ? "sorting"
                              : sortDirection === "asc"
                              ? "sorting_asc"
                              : "sorting_desc"
                          }
                          key={header.title}
                          onClick={() => {
                            if (header.key) {
                              setSortBy(header.key);
                              setSortDirection(
                                sortDirection === "asc" ? "desc" : "asc"
                              );
                            }
                          }}
                        >
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {showData.map((info, i) => (
                      <tr key={info.uuid}>
                        <td>{info.companyName}</td>
                        <td className="break_line">{info.fileName}</td>
                        <td className="break_line">{info.url}</td>
                        <td>{info.packageName}</td>
                        <td>{info.year}</td>
                        <td>{info.title}</td>
                        <td>{info.option}</td>
                        <td>
                          {info.addedAt ? formatDate(info.addedAt) : "----"}
                        </td>
                        <td
                          className={
                            info.addedAt
                              ? null
                              : new Date(info.dueDate) > new Date()
                              ? null
                              : "text-danger"
                          }
                        >
                          {formatDate(info.dueDate)}
                        </td>
                        <td
                          className={
                            info.status === "Uploaded"
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {info.status}
                        </td>
                        <td>
                          <div className="icons-wrapper icons-wrapper-upload">
                            <label
                              htmlFor={"upload" + info.uuid}
                              className="btn btn-primary mb-0"
                            >
                              <i className="feather icon-upload mr-2"></i>
                              Upload
                            </label>
                            <input
                              type="file"
                              name={"upload" + info.uuid}
                              id={"upload" + info.uuid}
                              accept=".pdf, .jpeg, .png, .jpg, .doc, .docx, .rtf, .odt,
                            .csv, .ppt, .xml, .txt, .zip, .psd, .ai, .eps"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                uploadFile(e, info);
                              }}
                            />
                            {info.addedAt && (
                              <>
                                <a
                                  href={`${process.env.REACT_APP_AWS}/${info.dbFileName}`}
                                  download
                                  className="btn btn-info"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="feather icon-download mr-2"></i>
                                  Download
                                </a>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => setDeleteUpload(info)}
                                  data-animation="slideInDown"
                                  data-toggle="modal"
                                  data-target="#deleteUploadModal"
                                >
                                  <i className="feather icon-x mr-2"></i>
                                  Delete
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      {tableHeader.map((header, i) => (
                        <th key={i}>{header.title}</th>
                      ))}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <div
                  className="dataTables_info"
                  id="default-datatable_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {(currentPage - 1) * numEntries + 1} to{" "}
                  {totalEntries > currentPage * numEntries
                    ? currentPage * numEntries
                    : totalEntries}{" "}
                  of {totalEntries} entries
                </div>
              </div>
              <div className="col-sm-12 col-md-7">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="default-datatable_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        currentPage === 1 ? "disabled" : null
                      }`}
                      id="default-datatable_previous"
                      onClick={() => {
                        if (currentPage !== 1) setCurrentPage(currentPage - 1);
                      }}
                    >
                      <div className="page-link">Previous</div>
                    </li>
                    {new Array(...Array(totalPages)).map((_, i) => (
                      <li
                        className={`paginate_button page-item ${
                          currentPage === i + 1 ? "active" : null
                        }`}
                        onClick={() => setCurrentPage(i + 1)}
                        key={i}
                      >
                        <div className="page-link">{i + 1}</div>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        currentPage === totalPages ? "disabled" : null
                      } `}
                      id="default-datatable_next"
                      onClick={() => {
                        if (currentPage !== totalPages)
                          setCurrentPage(currentPage + 1);
                      }}
                    >
                      <div className="page-link">Next</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteUpload && (
        <DeleteUploadModal
          deleteUpload={deleteUpload}
          loadContent={loadContent}
        />
      )}
    </div>
  );
}
