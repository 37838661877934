import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePackages from "../../../../../components/Tables/TablePackages";
import { setMessageError } from "../../../../../redux/actions";
import "./AllPackages.css";

export default function AllPackages() {
  const user = useSelector((state) => state.user);
  const [packages, setPackages] = useState([]);
  const dispatch = useDispatch();

  const loadPackages = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/packages",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setPackages(response.data);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    loadPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contentbar">
      <div className="row">
        <TablePackages data={packages} loadPackages={loadPackages} />
      </div>
    </div>
  );
}
