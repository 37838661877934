import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableVendor from "../../../../../components/Tables/TableVendor";
import { setMessageError } from "../../../../../redux/actions";
import "./AllVendors.css";

export default function AllVendors() {
  const user = useSelector((state) => state.user);
  const [vendors, setVendors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const dispatch = useDispatch();

  const loadVendors = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/Vendor",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const companiesResponse = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/companies",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCompanies(
        companiesResponse.data.map((company) => company.companyName).sort()
      );
      setVendors(response.data);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    loadVendors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contentbar">
      <div className="row">
        <TableVendor
          tableType={"Vendor"}
          data={vendors}
          loadUsers={loadVendors}
          companies={companies}
        />
      </div>
    </div>
  );
}
