export const LOAD_USER = "LOAD_USER";
export const loadUser = (user) => ({
  type: LOAD_USER,
  payload: user,
});

export const LOGIN_USER = "LOGIN_USER";
export const loginUser = (user) => ({
  type: LOGIN_USER,
  payload: user,
});

export const LOGOUT_USER = "LOGOUT_USER";
export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const SET_MESSAGE = "SET_MESSAGE";
export const setMessage = (messageInfo) => ({
  type: SET_MESSAGE,
  payload: messageInfo,
});

export const SET_MESSAGE_ERROR = "SET_MESSAGE_ERROR";
export const setMessageError = (messageError) => ({
  type: SET_MESSAGE_ERROR,
  payload: messageError,
});

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const loadNotifications = (notifications) => ({
  type: LOAD_NOTIFICATIONS,
  payload: notifications,
});
