import { useEffect, useState } from "react";
import "./AddVendorUserModal.css";

import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../redux/actions";

export default function AddVendorUserModal({ userRole, loadUsers, companies }) {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const [userForm, setUserForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    role: "",
    password: "",
    confirmPassword: "",
  });
  const [userFormValidation, setUserFormValidation] = useState({
    firstName: true,
    lastName: true,
    email: true,
    companyName: true,
    role: true,
    password: true,
    confirmPassword: true,
  });
  const inputs = [
    "firstName",
    "lastName",
    "email",
    "companyName",
    "role",
    "password",
    "confirmPassword",
  ];

  const updateUserForm = (e) => {
    if (e.target.name === "confirmPassword") {
      setUserFormValidation({
        ...userFormValidation,
        [e.target.name]:
          e.target.value === "" || e.target.value !== userForm.password
            ? false
            : true,
      });
    } else {
      setUserFormValidation({
        ...userFormValidation,
        [e.target.name]: e.target.value === "" ? false : true,
      });
    }
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  const addUserForm = (e) => {
    e.preventDefault();
    let isInvalid = false;
    inputs.forEach((input) => {
      if (userForm[input] === "") {
        isInvalid = true;
      }
    });
    if (userForm.password !== userForm.confirmPassword) isInvalid = true;
    setUserFormValidation({
      firstName: userForm.firstName === "" ? false : true,
      lastName: userForm.lastName === "" ? false : true,
      email: userForm.email === "" ? false : true,
      companyName: userForm.companyName === "" ? false : true,
      role: userForm.role === "" ? false : true,
      password: userForm.password === "" ? false : true,
      confirmPassword:
        userForm.confirmPassword === "" ||
        userForm.confirmPassword !== userForm.password
          ? false
          : true,
    });
    if (isInvalid) {
      return;
    }
    addUser(userForm);
  };

  const addUser = async (userForm) => {
    try {
      const role = userRole === "Castle Staff" ? "Castle" : "Administrator";

      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/users/" + role,
        userForm,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));
      loadUsers();
      setTimeout(() => {
        resetForm();
        document
          .querySelector(".vertical-layout")
          .classList.remove("modal-open");

        const modalBackdrop = document.querySelector(
          ".modal-backdrop.fade.show"
        );
        modalBackdrop.remove();

        const modal = document.querySelector(".modal.fade.show");
        modal.classList.remove("show");
        modal.style.display = "none";

        dispatch(setMessage(null));
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  const resetForm = () => {
    const suggestedPassword = uuidv4().slice(0, 15);
    setUserForm({
      firstName: "",
      lastName: "",
      email: "",
      role: userRole,
      password: suggestedPassword,
      confirmPassword: suggestedPassword,
      companyName: companies.length > 0 ? companies[0] : "",
    });
    setUserFormValidation({
      firstName: true,
      lastName: true,
      email: true,
      companyName: true,
      role: true,
      password: true,
      confirmPassword: true,
    });
  };

  useEffect(() => {
    const suggestedPassword = uuidv4().slice(0, 15);
    setUserForm({
      ...userForm,
      role: userRole,
      password: suggestedPassword,
      confirmPassword: suggestedPassword,
      companyName: companies.length > 0 ? companies[0] : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole, companies]);

  useEffect(() => {
    dispatch(setMessage(null));
  }, [dispatch]);

  return (
    <div
      className={`modal fade ${modal ? "show" : null}`}
      style={modal ? { display: "block" } : { display: "none" }}
      id="addVendorUserModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      onClick={() => setModal(false)}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header modal-header-info">
            <h5 className="modal-title" id="addVendorUserModalTitle">
              Add New {userRole} Company
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form
              className="needs-validation"
              onSubmit={addUserForm}
              onReset={resetForm}
            >
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="firstName">First name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      userFormValidation.firstName ? null : "is-invalid"
                    }`}
                    id="firstName"
                    name="firstName"
                    placeholder="First name"
                    value={userForm.firstName}
                    onChange={updateUserForm}
                  />
                  {!userFormValidation.firstName && (
                    <div className="invalid-feedback">
                      Please provide the first name.
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Last name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      userFormValidation.lastName ? null : "is-invalid"
                    }`}
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    value={userForm.lastName}
                    onChange={updateUserForm}
                  />
                  {!userFormValidation.lastName && (
                    <div className="invalid-feedback">
                      Please provide the last name.
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className={`form-control ${
                      userFormValidation.email ? null : "is-invalid"
                    }`}
                    id="email"
                    name="email"
                    placeholder="name@example.com"
                    value={userForm.email}
                    onChange={updateUserForm}
                  />
                  {!userFormValidation.email && (
                    <div className="invalid-feedback">
                      Please provide the email.
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="companyName">Company</label>
                  <select
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    onChange={updateUserForm}
                    value={userForm.companyName}
                  >
                    {companies &&
                      companies.map((company) => (
                        <option key={company}>{company}</option>
                      ))}
                  </select>
                  {!userFormValidation.companyName && (
                    <div className="invalid-feedback">
                      Please provide the company.
                    </div>
                  )}
                </div>
                {/* <div className="col-md-6 mb-3">
                  <label htmlFor="companyName">Company</label>
                  <input
                    type="text"
                    className={`form-control ${
                      userFormValidation.companyName ? null : "is-invalid"
                    }`}
                    id="companyName"
                    name="companyName"
                    placeholder="Company"
                    value={userForm.companyName}
                    onChange={updateUserForm}
                    disabled={
                      userRole === "Administrator" ||
                      userRole === "Castle Staff"
                        ? true
                        : false
                    }
                  />
                  {!userFormValidation.companyName && (
                    <div className="invalid-feedback">
                      Please provide the company.
                    </div>
                  )}
                </div> */}
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="role">Role</label>
                  <input
                    type="text"
                    className={`form-control ${
                      userFormValidation.role ? null : "is-invalid"
                    }`}
                    id="role"
                    name="role"
                    value={userForm.role}
                    disabled
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="password">Password</label>
                  <input
                    type="text"
                    className={`form-control ${
                      userFormValidation.password ? null : "is-invalid"
                    }`}
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={userForm.password}
                    onChange={updateUserForm}
                  />
                  {!userFormValidation.password && (
                    <div className="invalid-feedback">
                      Please provide the password.
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="text"
                    className={`form-control ${
                      userFormValidation.confirmPassword ? null : "is-invalid"
                    }`}
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={userForm.confirmPassword}
                    onChange={updateUserForm}
                  />
                  {!userFormValidation.confirmPassword && (
                    <div className="invalid-feedback">
                      {userForm.confirmPassword === ""
                        ? "Please provide the confirm password."
                        : "Passwords do not matches."}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row form-center-button">
                <button className="btn btn-info" type="submit">
                  Add user
                </button>

                <button className="btn btn-info" type="reset">
                  Reset
                </button>
              </div>
            </form>
          </div>
          {message && (
            <div className="modal-footer">
              <div
                className={`alert ${
                  message.status === "success"
                    ? "alert-success"
                    : "alert-danger"
                } alert-dismissible fade show mt-3`}
                role="alert"
              >
                {message.message}
                <button
                  type="button"
                  className="close"
                  onClick={() => dispatch(setMessage(null))}
                >
                  <span>&times;</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
