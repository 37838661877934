import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setMessageError } from "../../../../redux/actions";
import { formatDate } from "../../../../utils/formatDate";

export default function AdminCastleDashboard() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [vendors, setVendors] = useState({
    qty: 0,
    lastAdded: null,
  });
  const [filesUploaded, setFilesUploaded] = useState({
    qty: 0,
    lastUpload: null,
  });
  const [selections, setSelections] = useState({ completed: 0, total: 0 });

  useEffect(() => {
    loadDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDashboard = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/dashboard",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setVendors(response.data.vendors);
      setFilesUploaded(response.data.filesUploaded);
      setSelections(response.data.selections);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  return (
    <div className="row dashboard-row">
      <div className="col-lg-4">
        <Link to="/user/castle/vendors/view">
          <div className="card m-b-30">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-5">
                  <span className="action-icon badge badge-primary-inverse mr-0">
                    <i className="feather icon-user"></i>
                  </span>
                </div>
                <div className="col-7 text-right">
                  <h5 className="card-title font-14">Vendors</h5>
                  <h4 className="mb-0">{vendors.qty}</h4>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center">
                <div className="col-12">
                  <span className="font-13">
                    Last added:{" "}
                    {vendors.lastAdded
                      ? formatDate(vendors.lastAdded)
                      : "-------"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-lg-4">
        <Link to="/user/castle/vendors/uploads">
          <div className="card m-b-30">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-5">
                  <span className="action-icon badge badge-success-inverse mr-0">
                    <i className="fa fa-files-o"></i>
                  </span>
                </div>
                <div className="col-7 text-right">
                  <h5 className="card-title font-14">Uploaded Files</h5>
                  <h4 className="mb-0">{filesUploaded.qty}</h4>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center">
                <div className="col-12">
                  <span className="font-13">
                    Last upload:{" "}
                    {filesUploaded.lastUpload
                      ? formatDate(filesUploaded.lastUpload)
                      : "-------"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-lg-4">
        <Link to="/user/castle/vendors/uploads">
          <div className="card text-center m-b-30">
            <div className="card-header">
              <h5 className="card-title mb-0">Completed Selections</h5>
            </div>
            <div className="card-body px-0 pb-0">
              <p className="dash-analytic-icon">
                <i className="feather icon-bar-chart-2 primary-rgba text-primary"></i>
              </p>
              <h4 className="mb-3">
                {selections.completed} / {selections.total}
              </h4>
              <p>
                {Math.round((selections.completed / selections.total) * 100)}%
                Target
              </p>
              <div className="progress" style={{ height: "5px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width:
                      Math.round(
                        (selections.completed / selections.total) * 100
                      ) + "%",
                  }}
                  aria-valuenow={Math.round(
                    (selections.completed / selections.total) * 100
                  )}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
