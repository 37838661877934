import { useState } from "react";
import "./DeleteUploadModal.css";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../redux/actions";

export default function DeleteUploadModal({ deleteUpload, loadContent }) {
  const user = useSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const message = useSelector((state) => state.messageInfo);
  const dispatch = useDispatch();

  const deleteFile = async (material) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/vendor/ads/" + material.uuid,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));
      loadContent();

      setTimeout(() => {
        document
          .querySelector(".vertical-layout")
          .classList.remove("modal-open");

        const modalBackdrop = document.querySelector(
          ".modal-backdrop.fade.show"
        );
        modalBackdrop.remove();

        const modal = document.querySelector(".modal.fade.show");
        modal.classList.remove("show");
        modal.style.display = "none";

        dispatch(setMessage(null));
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  return (
    <div
      className={`modal fade ${modal ? "show" : null}`}
      style={modal ? { display: "block" } : { display: "none" }}
      id="deleteUploadModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      onClick={() => setModal(false)}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header modal-header-danger">
            <h5 className="modal-title" id="deleteUploadModalTitle">
              Confirm Delete Uploaded Material
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete:</p>
            <p className="modal-text mb-4">{deleteUpload.fileName}</p>
            <p className="modal-text">
              For: {deleteUpload.title} - {deleteUpload.option}
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => deleteFile(deleteUpload)}
            >
              Delete
            </button>

            {message && (
              <div
                className={`alert col-lg-12 ${
                  message.status === "success"
                    ? "alert-success"
                    : "alert-danger"
                } alert-dismissible fade show mt-3`}
                role="alert"
              >
                {message.message}
                <button
                  type="button"
                  className="close"
                  onClick={() => dispatch(setMessage(null))}
                >
                  <span>&times;</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
