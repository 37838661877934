import {
  loadNotifications,
  loadUser,
  loginUser,
  setMessage,
  setMessageError,
} from "./actions";
import axios from "axios";

export const getUser = () => async (dispatch, getState) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users/valid-token`,
        {
          email: user.email,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.data.isTokenValid) {
        dispatch(loadUser(user));
      } else {
        dispatch(loadUser(null));
        localStorage.removeItem("user");
      }
    } else {
      dispatch(loadUser(null));
    }
  } catch (e) {
    // dispatch(action2(e));
  }
};

export const login = (user, navigate) => async (dispatch, getState) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/login`,
      user
    );

    localStorage.setItem("user", JSON.stringify(response.data));

    setTimeout(() => {
      dispatch(loginUser(response.data));
      navigate("/user/dashboard");
    }, 2000);

    dispatch(
      setMessage({
        status: "success",
        message: "Login Successful!",
      })
    );
  } catch (e) {
    dispatch(
      setMessage({
        status: "error",
        message: "Username And/Or Password are incorrect!",
      })
    );
  }
};

export const getNotifications = () => async (dispatch, getState) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/notifications",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(loadNotifications(response.data));
    }
  } catch (err) {
    if (err.response.data.message) {
      dispatch(setMessageError(err.response.data.message));
    } else {
      dispatch(
        setMessageError(err.response.status + " - " + err.response.statusText)
      );
    }
  }
};
