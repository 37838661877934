import { useEffect, useState } from "react";
import "./UpdateUser.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setMessageError } from "../../../redux/actions";
import { useParams } from "react-router-dom";

export default function UpdateUser() {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);

  const { userUuid } = useParams();

  const dispatch = useDispatch();
  const [userForm, setUserForm] = useState(null);

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/update/" + userUuid,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setUserForm({ ...response.data, password: "", confirmPassword: "" });
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  const [userFormValidation, setUserFormValidation] = useState({
    firstName: true,
    lastName: true,
    email: true,
    companyName: true,
    role: true,
    password: true,
    confirmPassword: true,
  });

  const inputs = ["firstName", "lastName", "email", "companyName", "role"];

  const updateUserForm = (e) => {
    if (e.target.name === "confirmPassword") {
      setUserFormValidation({
        ...userFormValidation,
        [e.target.name]: e.target.value !== userForm.password ? false : true,
      });
    } else if (e.target.name === "password") {
      setUserFormValidation({
        ...userFormValidation,
        [e.target.name]:
          e.target.value !== "" && e.target.value.length < 6 ? false : true,
      });
    } else {
      setUserFormValidation({
        ...userFormValidation,
        [e.target.name]: e.target.value === "" ? false : true,
      });
    }
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  const submitUserForm = (e) => {
    e.preventDefault();
    let isInvalid = false;
    inputs.forEach((input) => {
      if (userForm[input] === "") {
        isInvalid = true;
      }
    });
    if (userForm.password !== userForm.confirmPassword) isInvalid = true;
    setUserFormValidation({
      firstName: userForm.firstName === "" ? false : true,
      lastName: userForm.lastName === "" ? false : true,
      email: userForm.email === "" ? false : true,
      companyName: userForm.companyName === "" ? false : true,
      role: userForm.role === "" ? false : true,
      password:
        userForm.password !== "" && userForm.password.length < 6 ? false : true,
      confirmPassword:
        userForm.confirmPassword !== userForm.password ? false : true,
    });
    if (isInvalid) {
      return;
    }
    updateUser(userForm);
  };

  const updateUser = async (userForm) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/users/update/" + userUuid,
        userForm,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));

      setTimeout(() => {
        dispatch(setMessage(null));
        window.history.back();
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setMessage(null));
  }, [dispatch]);

  return (
    <div className="contentbar">
      <div className="row">
        {userForm && (
          <form
            className="needs-validation col-lg-12 update-user-form"
            onSubmit={submitUserForm}
          >
            <h3 className="mb-5">Update User Details</h3>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="firstName">First name</label>
                <input
                  type="text"
                  className={`form-control ${
                    userFormValidation.firstName ? null : "is-invalid"
                  }`}
                  id="firstName"
                  name="firstName"
                  placeholder="First name"
                  value={userForm.firstName}
                  onChange={updateUserForm}
                />
                {!userFormValidation.firstName && (
                  <div className="invalid-feedback">
                    Please provide the first name.
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lastName">Last name</label>
                <input
                  type="text"
                  className={`form-control ${
                    userFormValidation.lastName ? null : "is-invalid"
                  }`}
                  id="lastName"
                  name="lastName"
                  placeholder="Last name"
                  value={userForm.lastName}
                  onChange={updateUserForm}
                />
                {!userFormValidation.lastName && (
                  <div className="invalid-feedback">
                    Please provide the last name.
                  </div>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className={`form-control ${
                    userFormValidation.email ? null : "is-invalid"
                  }`}
                  id="email"
                  name="email"
                  placeholder="name@example.com"
                  value={userForm.email}
                  onChange={updateUserForm}
                />
                {!userFormValidation.email && (
                  <div className="invalid-feedback">
                    Please provide the email.
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="companyName">Company</label>
                <input
                  type="text"
                  // className={`form-control ${
                  //   userFormValidation.companyName ? null : "is-invalid"
                  // }`}
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  placeholder="Company"
                  value={userForm.companyName}
                  // onChange={updateUserForm}
                  disabled
                />
                {/* {!userFormValidation.companyName && (
                  <div className="invalid-feedback">
                    Please provide the company.
                  </div>
                )} */}
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="role">Role</label>
                <input
                  type="text"
                  className={`form-control ${
                    userFormValidation.role ? null : "is-invalid"
                  }`}
                  id="role"
                  name="role"
                  value={userForm.role}
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="password">Password</label>
                <input
                  type="text"
                  className={`form-control ${
                    userFormValidation.password ? null : "is-invalid"
                  }`}
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={userForm.password}
                  onChange={updateUserForm}
                />
                {!userFormValidation.password && (
                  <div className="invalid-feedback">
                    {userForm.password === ""
                      ? "Please provide the password."
                      : userForm.password.length < 6
                      ? "New password should be at least 6 characters."
                      : ""}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="text"
                  className={`form-control ${
                    userFormValidation.confirmPassword ? null : "is-invalid"
                  }`}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={userForm.confirmPassword}
                  onChange={updateUserForm}
                />
                {!userFormValidation.confirmPassword && (
                  <div className="invalid-feedback">
                    {userForm.confirmPassword === ""
                      ? "Please provide the confirm password."
                      : "Passwords do not matches."}
                  </div>
                )}
              </div>
            </div>
            <div className="form-row form-center-button">
              <button className="btn btn-primary" type="submit">
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
      {message && (
        <div
          className={`alert ${
            message.status === "success" ? "alert-success" : "alert-danger"
          } alert-dismissible fade show mt-3`}
          role="alert"
        >
          {message.message}
          <button
            type="button"
            className="close"
            onClick={() => dispatch(setMessage(null))}
          >
            <span>&times;</span>
          </button>
        </div>
      )}
    </div>
  );
}
