import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/formatDate";
import specsFile from "../../../../assets/Elite Marketing Specs Booklet.pdf";
import { setMessageError } from "../../../../redux/actions";

export default function VendorDashboard() {
  const user = useSelector((state) => state.user);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();

  const [vendorSelections, setVendorSelections] = useState({
    total: 0,
    remaining: 0,
  });

  useEffect(() => {
    loadDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDashboard = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/dashboard",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setMessage(response.data.message);
      setVendorSelections(response.data.vendorSelections);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  return (
    <div className="row dashboard-row">
      <div className="col-lg-12 mb-4  download-btn">
        <a
          href={specsFile}
          target="_blank"
          rel="noreferrer"
          className="btn btn-outline-primary"
        >
          <i className="feather icon-download mr-2"></i> Download Our Specs
          Guide
        </a>
      </div>
      <div className="col-lg-12 mt-3 mb-3 dashboard-bg">
        <h3 className="mb-5">
          Welcome, <span className="text-primary">{user.name}</span>
        </h3>
        <h3 className="mb-4">About</h3>
        <div className="table-responsive">
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col">Company</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Joined</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{user.companyName}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{formatDate(user.signupAt)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {message === "No selection" && (
        <div className="col-lg-12 mt-3 mb-3 text-center dashboard-bg">
          <p className="dashboard-message">
            YOU HAVE BEEN ASSIGNED THE PACKAGE!
          </p>
          <p className="dashboard-message">
            BEFORE PROCEEDING PLEASE SELECT YOUR OPTIONS
          </p>
          <Link to="/user/vendor/select-options">
            <button type="button" className="btn btn-primary mt-4">
              Select Now
            </button>
          </Link>
        </div>
      )}
      {message === "No Package assigned" && (
        <div className="col-lg-12 mt-3 mb-3 dashboard-bg">
          <p className="dashboard-message">
            NO PACKAGE HAS BEEN ASSIGNED FOR YOU. CONTACT
          </p>
          <p className="dashboard-message text-success">
            <b>CASTLE ELITE MARKETING PROGRAM</b>
          </p>
          <p className="dashboard-message">TO HAVE A PACKAGE</p>
        </div>
      )}
      {message === "Selection made" && (
        <>
          <div className="col-lg-4">
            <Link to="/user/vendor/package">
              <div className="card m-b-30">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-5">
                      <span className="action-icon badge badge-primary-inverse mr-0">
                        <i className="fa fa-files-o"></i>
                      </span>
                    </div>
                    <div className="col-7 text-right">
                      <h5 className="card-title font-14">Package Selections</h5>
                      <h4 className="mb-0">{vendorSelections.total}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-4">
            <Link to="/user/vendor/artwork&material">
              <div className="card m-b-30">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-5">
                      <span className="action-icon badge badge-success-inverse mr-0">
                        <i className="fa fa-files-o"></i>
                      </span>
                    </div>
                    <div className="col-7 text-right">
                      <h5 className="card-title font-14">Remaining Uploads</h5>
                      <h4 className="mb-0">{vendorSelections.remaining}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-4">
            <Link to="/user/vendor/artwork&material">
              <div className="card text-center m-b-30">
                <div className="card-header">
                  <h5 className="card-title mb-0">Package Material Uploads</h5>
                </div>
                <div className="card-body px-0 pb-0">
                  <p className="dash-analytic-icon">
                    <i className="feather icon-bar-chart-2 primary-rgba text-primary"></i>
                  </p>
                  <h4 className="mb-3">
                    {vendorSelections.total - vendorSelections.remaining} /{" "}
                    {vendorSelections.total}
                  </h4>
                  <div className="progress" style={{ height: "5px" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width:
                          Math.round(
                            ((vendorSelections.total -
                              vendorSelections.remaining) /
                              vendorSelections.total) *
                              100
                          ) + "%",
                      }}
                      aria-valuenow={Math.round(
                        ((vendorSelections.total - vendorSelections.remaining) /
                          vendorSelections.total) *
                          100
                      )}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
