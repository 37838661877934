import { useEffect, useState } from "react";
import "./AddVendorCompanyModal.css";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../redux/actions";

export default function AddVendorCompanyModal({ loadUsers }) {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const [companyForm, setCompanyForm] = useState("");
  const [companyFormValidation, setCompanyFormValidation] = useState(true);

  const addUserForm = (e) => {
    e.preventDefault();

    setCompanyFormValidation(companyForm === "" ? false : true);
    if (companyForm === "") {
      return;
    }
    addCompany();
  };

  const addCompany = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/companies",
        { companyName: companyForm },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));
      setCompanyForm("");
      loadUsers();
      setTimeout(() => {
        document
          .querySelector(".vertical-layout")
          .classList.remove("modal-open");

        const modalBackdrop = document.querySelector(
          ".modal-backdrop.fade.show"
        );
        modalBackdrop.remove();

        const modal = document.querySelector(".modal.fade.show");
        modal.classList.remove("show");
        modal.style.display = "none";

        dispatch(setMessage(null));
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setMessage(null));
  }, [dispatch]);

  return (
    <div
      className={`modal fade ${modal ? "show" : null}`}
      style={modal ? { display: "block" } : { display: "none" }}
      id="addVendorCompanyModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      onClick={() => setModal(false)}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addVendorCompanyModalTitle">
              Add Company
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="needs-validation" onSubmit={addUserForm}>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="company">Company Name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      companyFormValidation ? null : "is-invalid"
                    }`}
                    id="company"
                    name="company"
                    placeholder="Company"
                    value={companyForm}
                    onChange={(e) => {
                      setCompanyForm(e.target.value);
                      setCompanyFormValidation(
                        e.target.value === "" ? false : true
                      );
                    }}
                  />
                  {!companyFormValidation && (
                    <div className="invalid-feedback">
                      Please provide the company.
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row form-center-button">
                <button className="btn btn-primary" type="submit">
                  Add
                </button>
              </div>
            </form>
          </div>
          {message && (
            <div className="modal-footer">
              <div
                className={`alert ${
                  message.status === "success"
                    ? "alert-success"
                    : "alert-danger"
                } alert-dismissible fade show mt-3`}
                role="alert"
              >
                {message.message}
                <button
                  type="button"
                  className="close"
                  onClick={() => dispatch(setMessage(null))}
                >
                  <span>&times;</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
