import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessageError } from "../../../../redux/actions";
import "./Reports.css";
import { useState } from "react";
import { formatDate } from "../../../../utils/formatDate";
import { CSVLink } from "react-csv";

export default function Reports() {
  const user = useSelector((state) => state.user);
  const [reports, setReports] = useState(null);
  const dispatch = useDispatch();

  const loadReports = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/reports",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setReports(response.data);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    loadReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contentbar">
      <div className="row dashboard-row">
        <div className="col-lg-12 dashboard-bg mt-3 mb-3">
          <h3>Download Reports</h3>
          <p>
            Get information about uploads - recent, outstanding (next 30 days) &
            overdue
          </p>
        </div>
        {reports && (
          <>
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h5 className="card-title mb-0">Weekly Report</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row align-items-end">
                    <div className="col-lg-6">
                      <p>
                        Report generated on{" "}
                        {formatDate(reports.weeklyReport?.date)}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <a
                        href={reports.weeklyReport?.path}
                        className="btn btn-success mb-3 text-white "
                      >
                        <i className="feather icon-download mr-3"></i>
                        Download Report
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h5 className="card-title mb-0">Monthly Report</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row align-items-end">
                    <div className="col-lg-6">
                      <p>
                        Report generated on{" "}
                        {formatDate(reports.monthlyReport?.date)}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <a
                        href={reports.monthlyReport?.path}
                        className="btn btn-primary mb-3 text-white"
                      >
                        <i className="feather icon-download mr-3"></i>
                        Download Report
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h5 className="card-title mb-0">Vendors</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row align-items-end">
                    <div className="col-lg-6">
                      <p>List of Current Vendors</p>
                    </div>
                    <div className="col-lg-6">
                      <CSVLink
                        data={reports?.vendors}
                        filename={`Vendors - by ${formatDate(
                          new Date().toLocaleDateString()
                        )}.csv`}
                      >
                        <button className="btn btn-secondary mb-3 text-white">
                          <i className="feather icon-download mr-3"></i>
                          Download List
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card m-b-30">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h5 className="card-title mb-0">Vendor Selections</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row align-items-end">
                    <div className="col-lg-6">
                      <p>List of Current Vendor Selections</p>
                    </div>
                    <div className="col-lg-6">
                      <CSVLink
                        data={reports?.selectedOptions}
                        filename={`Vendor Selections - by ${formatDate(
                          new Date().toLocaleDateString()
                        )}.csv`}
                      >
                        <button className="btn btn-info mb-3 text-white">
                          <i className="feather icon-download mr-3"></i>
                          Download Selections
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
