export const formatDate = (numDate) => {
  return new Date(Date.parse(numDate) + 11 * 60 * 60 * 1000).toLocaleDateString(
    "en-US",
    {
      month: "short",
      day: "numeric",
      year: "numeric",
    }
  );
};

export const formatDueDate = (numDate) => {
  const date = new Date(Date.parse(numDate) + 11 * 60 * 60 * 1000);
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" : ""}${
    date.getMonth() + 1
  }-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;
};
