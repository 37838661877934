import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/User/Dashboard";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import PageNotFound from "./pages/PageNotFound";

import Administrators from "./pages/User/Admin/Administrators";
import CastleStaff from "./pages/User/Admin/CastleStaff";

import AllVendors from "./pages/User/Castle/Vendors/AllVendors";
import Uploads from "./pages/User/Castle/Vendors/Uploads";
import UpdateVendorSelection from "./pages/User/Castle/Vendors/UpdateVendorSelection";

import AdTypes from "./pages/User/Castle/ManageAds/AdTypes";
import AdDueDates from "./pages/User/Castle/ManageAds/AdDueDates";
import EditAdDueDates from "./pages/User/Castle/ManageAds/EditAdDueDates";

import EditPackage from "./pages/User/Castle/Packages/EditPackage";
import AllPackages from "./pages/User/Castle/Packages/AllPackages";

import Reports from "./pages/User/Castle/Reports";

import UserLayout from "./pages/User/UserLayout";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUser } from "./redux/thunks";

import Notifications from "./pages/User/Notifications";
import UpdateUser from "./pages/User/UpdateUser";

import SelectPackageOptions from "./pages/User/Vendor/SelectPackageOptions";
import ArtworkMaterial from "./pages/User/Vendor/ArtworkMaterial";
import Company from "./pages/User/Vendor/Company";
import MyPackage from "./pages/User/Vendor/MyPackage";

import Toast from "./components/Messages/Toast";

function App() {
  const user = useSelector((state) => state.user);
  const messageError = useSelector((state) => state.messageError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      document.title = `${user.role} - Castle Elite Marketing Program`;
    } else {
      document.title = `Castle Elite Marketing Program`;
    }
  }, [user]);

  return (
    <div className="App">
      {!user && (
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<PageNotFound redirectLink={"/login"} />} />
        </Routes>
      )}
      {user && (
        <Routes>
          <Route path="/" element={<UserLayout />}>
            <Route path="/login" element={<Navigate to="/user/dashboard" />} />
            <Route path="/user/dashboard" element={<Dashboard />} />
            <Route path="/user/notifications" element={<Notifications />} />
            <Route
              path="/user/update-user/:userUuid"
              element={<UpdateUser />}
            />
            <Route
              path="/user/admin/administrators"
              element={<Administrators />}
            />
            <Route path="/user/admin/castle-staff" element={<CastleStaff />} />

            <Route path="/user/castle/vendors/view" element={<AllVendors />} />
            <Route
              path="/user/castle/vendors/selections"
              element={<UpdateVendorSelection />}
            />
            <Route path="/user/castle/vendors/uploads" element={<Uploads />} />
            <Route path="/user/castle/reports" element={<Reports />} />
            <Route
              path="/user/castle/packages/view"
              element={<AllPackages />}
            />
            <Route
              path="/user/castle/packages/:uuid"
              element={<EditPackage />}
            />

            {/* <Route
              path="/user/castle/uploads/reports"
              element={<CastleStaff />}
            /> */}
            <Route path="/user/castle/ads/types" element={<AdTypes />} />
            <Route path="/user/castle/ads/due-dates" element={<AdDueDates />} />
            <Route
              path="/user/castle/ads/due-dates/:year"
              element={<EditAdDueDates />}
            />
            <Route path="/user/vendor/package" element={<MyPackage />} />
            <Route path="/user/vendor/company" element={<Company />} />
            <Route path="/user/vendor/update-email" element={<CastleStaff />} />
            <Route
              path="/user/vendor/artwork&material"
              element={<ArtworkMaterial />}
            />
            <Route
              path="/user/vendor/select-options"
              element={<SelectPackageOptions />}
            />
          </Route>
          <Route
            path="*"
            element={<PageNotFound redirectLink={"/user/dashboard"} />}
          />
        </Routes>
      )}
      {messageError && <Toast />}
    </div>
  );
}

export default App;
