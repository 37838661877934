import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Uploads.css";

import TableUploadedMaterial from "../../../../../components/Tables/TableUploadedMaterial";
import { setMessageError } from "../../../../../redux/actions";

export default function Uploads() {
  const user = useSelector((state) => state.user);
  const [content, setContent] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      loadContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadContent = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/vendor/ads",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setContent(
        response.data.map((item) => ({
          ...item,
          status: item.addedAt ? "Uploaded" : "Not Uploaded",
        }))
      );
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  return (
    <div className="contentbar">
      <TableUploadedMaterial data={content} loadContent={loadContent} />
    </div>
  );
}
