import { useEffect, useState } from "react";
import "./AdTypeModal.css";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../redux/actions";

const defaultForm = {
  title: "",
  period: "",
  type: "",
  filteredOptions: "",
  isActive: true,
  category: "",
};

const defaultValidation = {
  title: true,
  period: true,
  type: true,
  filteredOptions: true,
};

export default function AdTypeModal({ selectedAd, loadAdTypes }) {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const [categories, setCategories] = useState(null);

  const [adTypeForm, setAdTypeForm] = useState(defaultForm);
  const [adTypeFormValidation, setAdTypeFormValidation] =
    useState(defaultValidation);

  const inputs = ["title", "period", "type", "filteredOptions"];

  const loadCategories = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/categories",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setCategories(response.data);
      setAdTypeForm({ ...adTypeForm, category: response.data[0].category });
    } catch (err) {
      dispatch(
        setMessage({
          status: "error",
          message: err.response.status + " - " + err.response.statusText,
        })
      );
    }
  };

  const updateAdTypeForm = (e) => {
    setAdTypeFormValidation({
      ...adTypeFormValidation,
      [e.target.name]: e.target.value === "" ? false : true,
    });
    setAdTypeForm({ ...adTypeForm, [e.target.name]: e.target.value });
  };

  const addAdTypeForm = (e) => {
    e.preventDefault();
    let isInvalid = false;
    inputs.forEach((input) => {
      if (adTypeForm[input] === "") {
        isInvalid = true;
      }
    });
    if (adTypeForm.password !== adTypeForm.confirmPassword) isInvalid = true;
    setAdTypeFormValidation({
      title: adTypeForm.title === "" ? false : true,
      period: adTypeForm.period === "" ? false : true,
      type: adTypeForm.type === "" ? false : true,
      filteredOptions: adTypeForm.filteredOptions === "" ? false : true,
    });
    if (isInvalid) {
      return;
    }
    adTypeRequest(adTypeForm);
  };

  const adTypeRequest = async (adTypeForm) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/ads",
        adTypeForm,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));

      loadAdTypes();
      setTimeout(() => {
        document
          .querySelector(".vertical-layout")
          .classList.remove("modal-open");

        const modalBackdrop = document.querySelector(
          ".modal-backdrop.fade.show"
        );
        modalBackdrop.remove();

        const modal = document.querySelector(".modal.fade.show");
        modal.classList.remove("show");
        modal.style.display = "none";

        dispatch(setMessage(null));
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (selectedAd) {
      setAdTypeForm({
        ...selectedAd,
        filteredOptions: selectedAd.filteredOptions
          .map((data) => data.option)
          .join("; "),
      });
    } else {
      setAdTypeForm(defaultForm);
    }
    setAdTypeFormValidation(defaultValidation);
  }, [selectedAd]);

  useEffect(() => {
    dispatch(setMessage(null));
    setAdTypeFormValidation(defaultValidation);
    loadCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div
      className={`modal fade ${modal ? "show" : null}`}
      style={modal ? { display: "block" } : { display: "none" }}
      id="adTypeModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      onClick={() => setModal(false)}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="adTypeModalTitle">
              {selectedAd ? "Update Ad Type" : "Add New Ad Type"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="needs-validation" onSubmit={addAdTypeForm}>
              <div className="form-row">
                <div className="col-lg-12 mb-3">
                  <label htmlFor="title">Ad Type Name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      adTypeFormValidation.title ? null : "is-invalid"
                    }`}
                    id="title"
                    name="title"
                    placeholder="Ad Type Name"
                    value={adTypeForm.title}
                    onChange={updateAdTypeForm}
                  />
                  {!adTypeFormValidation.title && (
                    <div className="invalid-feedback">
                      Please provide the ad type name.
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="period">Period</label>
                  <input
                    type="text"
                    className={`form-control ${
                      adTypeFormValidation.period ? null : "is-invalid"
                    }`}
                    id="period"
                    name="period"
                    placeholder="Period (monthly, bimonthly, seasonal)"
                    value={adTypeForm.period}
                    onChange={updateAdTypeForm}
                  />
                  {!adTypeFormValidation.period && (
                    <div className="invalid-feedback">
                      Please provide the period.
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="type">Type</label>
                  <input
                    type="text"
                    className={`form-control ${
                      adTypeFormValidation.type ? null : "is-invalid"
                    }`}
                    id="type"
                    name="type"
                    placeholder="Type (issues, impressions, month, week)"
                    value={adTypeForm.type}
                    onChange={updateAdTypeForm}
                  />
                  {!adTypeFormValidation.type && (
                    <div className="invalid-feedback">
                      Please provide the type.
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="filteredOptions">Options</label>
                  <input
                    type="text"
                    className={`form-control ${
                      adTypeFormValidation.filteredOptions ? null : "is-invalid"
                    }`}
                    id="filteredOptions"
                    name="filteredOptions"
                    placeholder="Options (separate by semi-colon)"
                    value={adTypeForm.filteredOptions}
                    onChange={updateAdTypeForm}
                  />
                  {!adTypeFormValidation.filteredOptions && (
                    <div className="invalid-feedback">
                      Please provide the options, separated by semi-colon.
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3 custom-radio-button">
                  <label className="">Active</label>
                  <div className="mb-3 ml-2">
                    <div className="form-check-inline radio-success">
                      <input
                        type="radio"
                        id="isActiveYes"
                        name="isActive"
                        checked={adTypeForm.isActive}
                        onChange={() =>
                          setAdTypeForm({ ...adTypeForm, isActive: true })
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isActiveYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check-inline radio-danger">
                      <input
                        type="radio"
                        id="isActiveNo"
                        name="isActive"
                        checked={!adTypeForm.isActive}
                        onChange={() =>
                          setAdTypeForm({ ...adTypeForm, isActive: false })
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isActiveNo"
                      >
                        {" "}
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="categoryId">Category</label>
                    <select
                      className="form-control"
                      id="category"
                      name="category"
                      onChange={(e) =>
                        setAdTypeForm({
                          ...adTypeForm,
                          category: e.target.value,
                        })
                      }
                      value={adTypeForm.category}
                    >
                      {categories &&
                        categories.map((cat) => (
                          <option key={cat.category}>{cat.category}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-row form-center-button">
                <button className="btn btn-primary" type="submit">
                  {selectedAd ? "Update Ad Type" : "Add Ad Type"}
                </button>
              </div>
            </form>
          </div>
          {message && (
            <div className="modal-footer">
              <div
                className={`alert ${
                  message.status === "success"
                    ? "alert-success"
                    : "alert-danger"
                } alert-dismissible fade show mt-3`}
                role="alert"
              >
                {message.message}
                <button
                  type="button"
                  className="close"
                  onClick={() => dispatch(setMessage(null))}
                >
                  <span>&times;</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
