import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableAdTypes from "../../../../../components/Tables/TableAdTypes";
import "./AdTypes.css";

export default function AdTypes() {
  const user = useSelector((state) => state.user);
  const [adTypes, setAdTypes] = useState([]);

  const loadAdTypes = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/ads",
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    setAdTypes(response.data);
  };

  useEffect(() => {
    loadAdTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contentbar">
      <div className="row">
        <TableAdTypes data={adTypes} loadAdTypes={loadAdTypes} />
      </div>
    </div>
  );
}
