import { createStore, combineReducers } from "redux";
import { applyMiddleware } from "redux";
import thunk from "redux-thunk";

// import reducers
import { user, messageInfo, messageError, notifications } from "./reducers";

const reducers = { user, messageInfo, messageError, notifications };
const rootReducer = combineReducers(reducers);

export const configureStore = () =>
  createStore(rootReducer, applyMiddleware(thunk));
