import { useEffect, useState } from "react";
import "./EditAdDueDates.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setMessageError } from "../../../../../redux/actions";
import { useParams } from "react-router-dom";
import { formatDueDate } from "../../../../../utils/formatDate";

export default function EditAdDueDates() {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);

  const { year } = useParams();

  const dispatch = useDispatch();
  const [dueDatesForm, setDueDatesForm] = useState(null);
  const [dueDatesFormValidation, setDueDatesFormValidation] = useState(null);
  const [inputs, setInputs] = useState(null);
  const [activeAds, setActiveAds] = useState([]);
  const [isNewDueDates, setIsNewDueDates] = useState(true);

  const months = { 
    'JAN': '12',
    'FEB': '01',
    'MAR': '02',
    'APR': '03',
    'MAY': '04',
    'JUN': '05',
    'JUL': '06',
    'AUG': '07',
    'SEP': '08',
    'OCT': '09',
    'NOV': '10',
    'DEC': '11',
    
    'JAN / FEB': '11',
    'MAR / APR': '01',
    'MAY / JUN': '03',
    'JUL / AUG': '05',
    'SEP / OCT': '07',
    'NOV / DEV': '09',
  };
  const dates = { 'monthly': '01', 'bi-monthly': '15', 'seasonal': '01' };

  useEffect(() => {
    loadYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadYear = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/ads/due-dates/" + year,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (!response.data.message) {
        setActiveAds(response.data);
        setIsNewDueDates(false);
      } else {
        const adsResponse = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/ads/active",
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        setActiveAds(adsResponse.data);
        setIsNewDueDates(true);
      }
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    const options = {};
    const optionsValidation = {};
    const inputsValidation = [];
    activeAds.forEach((ad) => {
      const period = ad.period;
      ad.filteredOptions.forEach((opt) => {
        // console.log(new Date(Date.parse(opt.dueDate) + 24 * 60 * 60 * 1000));
        // key: adDueDate id or adOptions id if adDueDate id does not exist, value: due date
        const month   = months[opt.option] ? months[opt.option] : '01';
        const date    = dates[period] ? dates[period] : '01';
        const _year   = opt.option.includes( 'JAN' ) ? parseInt( year ) - 1 : year;
        const dueDate = `${_year}-${month}-${date}`;
        options[opt.id] = opt.dueDate ? formatDueDate(opt.dueDate) : dueDate;
        // options[opt.id] = opt.dueDate ? formatDueDate(opt.dueDate) : "";
        optionsValidation[opt.id] = true;
        inputsValidation.push(opt.id);
      });
    });

    setDueDatesForm(options);
    setDueDatesFormValidation(optionsValidation);
    setInputs(inputsValidation);
  }, [activeAds]);

  const updateDueDate = (e, id) => {
    setDueDatesForm({ ...dueDatesForm, [id]: e.target.value });
    setDueDatesFormValidation({
      ...dueDatesFormValidation,
      [id]: e.target.value === "" ? false : true,
    });
  };

  const submitUserForm = (e) => {
    e.preventDefault();
    let isInvalid = false;
    const optionsValidation = {};

    inputs.forEach((input) => {
      if (dueDatesForm[input] === "") {
        isInvalid = true;
      }
      optionsValidation[input] = dueDatesForm[input] === "" ? false : true;
    });
    setDueDatesFormValidation(optionsValidation);

    if (isInvalid) {
      return;
    }

    const dueDatesArray = [];
    if (isNewDueDates) {
      inputs.forEach((input) => {
        // dueDatesArray.push({
        //   adOptionId: input,
        //   dueDate: new Date(dueDatesForm[input]),
        // });
        dueDatesArray.push({ adOptionId: input, dueDate: dueDatesForm[input] });
        // dueDatesArray.push({ adOptionId: input, dueDate: "2022-05-05" });
        // dueDatesArray.push({ [input]: dueDatesForm[input] });
      });
    } else {
      inputs.forEach((input) => {
        // dueDatesArray.push({
        //   id: input,
        //   dueDate: new Date(dueDatesForm[input]),
        // });
        dueDatesArray.push({ id: input, dueDate: dueDatesForm[input] });
        // dueDatesArray.push({ id: input, dueDate: "2022-05-01" });
      });
    }

    updateYear(dueDatesArray);
  };

  const updateYear = async (dueDatesArray) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/ads/due-dates/" + year,
        dueDatesArray,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));

      setTimeout(() => {
        dispatch(setMessage(null));
        // window.history.back();
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setMessage(null));
  }, [dispatch]);

  return (
    <div className="contentbar">
      <div className="row">
        <form
          className="needs-validation col-lg-12 update-user-form"
          onSubmit={submitUserForm}
        >
          <h3 className="mb-5">{"Manage Due Dates for " + year}</h3>

          {dueDatesForm && (
            <>
              {activeAds.map((info) => (
                <div
                  className="form-row mb-4 pb-3 due-date-wrapper"
                  key={info.title}
                >
                  <h5 className="col-lg-12 mb-4">{info.title}</h5>
                  {info.filteredOptions.map((opt) => (
                    <div className="col-sm-6 col-lg-4 p-2 mb-2" key={opt.id}>
                      <label className="mb-0">{opt.option}</label>
                      <input
                        type="date"
                        id="autoclose-date"
                        className={`datepicker-here form-control ${
                          dueDatesFormValidation[opt.id]
                            ? ""
                            : "due-date-invalid"
                        }`}
                        onChange={(e) => updateDueDate(e, opt.id)}
                        value={dueDatesForm[opt.id]}
                      />
                    </div>
                  ))}
                </div>
              ))}

              <div className="form-row form-center-button">
                <button className="btn btn-primary" type="submit">
                  Save Changes
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      {message && (
        <div
          className={`alert ${
            message.status === "success" ? "alert-success" : "alert-danger"
          } alert-dismissible fade show mt-3`}
          role="alert"
        >
          {message.message}
          <button
            type="button"
            className="close"
            onClick={() => dispatch(setMessage(null))}
          >
            <span>&times;</span>
          </button>
        </div>
      )}
    </div>
  );
}
