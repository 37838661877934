import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../../../../redux/actions";

import "./SelectPackageOptions.css";

export default function SelectPackageOptions() {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);

  const [companyPackage, setCompanyPackage] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectionForm, setSelectionForm] = useState(null);
  const [selectionQty, setSelectionQty] = useState(null);
  const [selectionFormValidation, setSelectionFormValidation] = useState(null);
  const [adTypeIds, setAdTypeIds] = useState(null);
  const [selectionOptions, setSelectionOptions] = useState([]);

  useEffect(() => {
    loadSelection();
    dispatch(setMessage(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSelection = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/company/package&selection?companyName=" +
          encodeURIComponent( user.companyName ),
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCompanyPackage(response.data.package);
      setSelectionOptions(response.data.selectionOptions);
    } catch (error) {
      navigate("/user/dashboard");
    }
  };

  useEffect(() => {
    const options = {};
    const optionsValidation = {};
    const optionsQty = {};
    const inputsValidation = [];
    selectionOptions.forEach((cat) => {
      cat.adType.forEach((ad) => {
        options[ad.adTypeId] = [];
        optionsValidation[ad.adTypeId] = "";
        optionsQty[ad.adTypeId] = ad.type === "impressions" ? 1 : ad.qty;
        inputsValidation.push(ad.adTypeId);
      });
    });

    setSelectionForm(options);
    setSelectionFormValidation(optionsValidation);
    setSelectionQty(optionsQty);
    setAdTypeIds(inputsValidation);
  }, [selectionOptions]);

  const updateForm = (e, option) => {
    const adTypeId = option.adTypeId;

    let messageValidation = "";
    if (e.target.checked) {
      if (selectionForm[adTypeId].length === selectionQty[adTypeId]) {
        messageValidation = "Max number of selections reached";
      } else {
        messageValidation = "";
        setSelectionForm({
          ...selectionForm,
          [adTypeId]: [...selectionForm[adTypeId], option.id],
        });
      }
    } else {
      if (selectionForm[adTypeId].length <= selectionQty[adTypeId]) {
        messageValidation =
          "requires " + selectionQty[adTypeId] + " selections";

        const remainingSelection = selectionForm[adTypeId].filter(
          (opt) => opt !== option.id
        );
        setSelectionForm({
          ...selectionForm,
          [adTypeId]: remainingSelection,
        });
      } else {
        messageValidation = "";
      }
    }

    setSelectionFormValidation({
      ...selectionFormValidation,
      [adTypeId]: messageValidation,
    });
  };

  const submitSelectionForm = (e) => {
    e.preventDefault();

    let isInvalid = false;
    const optionsValidation = {};
    adTypeIds.forEach((id) => {
      if (selectionForm[id].length !== selectionQty[id]) {
        isInvalid = true;
        optionsValidation[id] = "requires " + selectionQty[id] + " selections";
      } else {
        optionsValidation[id] = "";
      }
    });
    setSelectionFormValidation(optionsValidation);

    if (isInvalid) {
      return;
    }

    const adOptionIdsArray = [];
    adTypeIds.forEach((ad) => {
      selectionForm[ad].forEach((item) => {
        adOptionIdsArray.push({ adOptionId: item });
      });
    });

    submitSelections(adOptionIdsArray);
  };

  const submitSelections = async (adOptionIdsArray) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          "/company/package&selection?companyName=" +
          encodeURIComponent( user.companyName ),
        adOptionIdsArray,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch(setMessage(response.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        navigate("/user/dashboard");
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  return (
    <div className="contentbar">
      <div className="row package-selection-bg">
        <h2 className="col-lg-12">Elite Sponsorship Levels</h2>
        {companyPackage && (
          <>
            <p className="col-lg-12">
              Your Package Name Is :{" "}
              <span className="text-success">{companyPackage.packageName}</span>
            </p>
          </>
        )}
      </div>
      <div className="row">
        <form
          className="needs-validation col-lg-12 dashboard-bg"
          onSubmit={submitSelectionForm}
        >
          {selectionForm && (
            <>
              {selectionOptions.map((info) => (
                <div
                  className="form-row mb-4 pb-3 due-date-wrapper"
                  key={info.category}
                >
                  <h5 className="col-lg-12">{info.category}</h5>
                  <p className="col-lg-12 mb-4">{info.description}</p>
                  {info.adType.map((opt) => (
                    <div
                      className="col-md-12 col-lg-6 col-xl-4 p-2 mb-2"
                      key={opt.uuid}
                    >
                      <div
                        className={`card border-secondary ${
                          selectionFormValidation[opt.adTypeId] !== ""
                            ? "card-body-error"
                            : null
                        }`}
                      >
                        <div className="card-body">
                          <label className="mb-0">{opt.title}</label>
                          {opt.type === "impressions" ? (
                            <p className="mb-2">
                              {new Intl.NumberFormat("en-US", {
                                maximumFractionDigits: 0,
                              }).format(opt.qty)}{" "}
                              {opt.type} - select 1 month
                            </p>
                          ) : (
                            <p className="mb-2">
                              select {opt.qty} {opt.type}
                            </p>
                          )}

                          {opt.options.map((option) => (
                            <div
                              key={option.id}
                              className={`form-check mb-1 ${
                                opt.options.length > 4
                                  ? "form-check-inline"
                                  : null
                              }`}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  selectionForm[option.adTypeId]?.includes(
                                    option.id
                                  ) || false
                                }
                                name={option.id}
                                id={option.id}
                                onChange={(e) => updateForm(e, option)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={option.id}
                              >
                                {option.option}
                              </label>
                            </div>
                          ))}
                          {selectionFormValidation[opt.adTypeId] !== "" && (
                            <div className="invalid-feedback">
                              {selectionFormValidation[opt.adTypeId]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}

              <div className="form-row form-center-button">
                <button className="btn btn-primary" type="submit">
                  Submit Selections
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      {message && (
        <div
          className={`alert ${
            message.status === "success" ? "alert-success" : "alert-danger"
          } alert-dismissible fade show mt-3`}
          role="alert"
        >
          {message.message}
          <button
            type="button"
            className="close"
            onClick={() => dispatch(setMessage(null))}
          >
            <span>&times;</span>
          </button>
        </div>
      )}
    </div>
  );
}
