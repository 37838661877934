import { useEffect, useState } from "react";
import "./AddPackageCompanyModal.css";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../redux/actions";
import { formatPrice } from "../../../utils/formatPrice";

export default function AddPackageCompanyModal({
  selectedPackage,
  loadPackages,
}) {
  const user = useSelector((state) => state.user);
  const message = useSelector((state) => state.messageInfo);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [reassign, setReassign] = useState(false);

  const [companies, setCompanies] = useState(null);

  const [companyForm, setCompanyForm] = useState("");

  const loadCompanies = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/companies",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCompanies(response.data);
      if (response.data.length > 0) {
        setCompanyForm(response.data[0].companyName);
      }
    } catch (err) {
      dispatch(
        setMessage({
          status: "error",
          message: err.response.status + " - " + err.response.statusText,
        })
      );
    }
  };

  const addAdTypeForm = (e) => {
    e.preventDefault();

    if (companyForm === "") {
      return;
    }
    assignCompany();
  };

  const assignCompany = async () => {
    try {
      const companyId = companies.filter(
        (comp) => comp.companyName.trim() === companyForm.trim()
      )[0].id;

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/packages/${selectedPackage.id}/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));

      if (response.data.status === "warning") {
        setReassign(true);
      }

      if (response.data.status === "success") {
        loadPackages();
        setTimeout(() => {
          document
            .querySelector(".vertical-layout")
            .classList.remove("modal-open");

          const modalBackdrop = document.querySelector(
            ".modal-backdrop.fade.show"
          );
          modalBackdrop.remove();

          const modal = document.querySelector(".modal.fade.show");
          modal.classList.remove("show");
          modal.style.display = "none";

          dispatch(setMessage(null));
        }, 2000);
      }
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  const reassignCompany = async () => {
    try {
      const companyId = companies.filter(
        (comp) => comp.companyName.trim() === companyForm.trim()
      )[0].id;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/packages/${selectedPackage.id}/${companyId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      dispatch(setMessage(response.data));

      loadPackages();
      setTimeout(() => {
        document
          .querySelector(".vertical-layout")
          .classList.remove("modal-open");

        const modalBackdrop = document.querySelector(
          ".modal-backdrop.fade.show"
        );
        modalBackdrop.remove();

        const modal = document.querySelector(".modal.fade.show");
        modal.classList.remove("show");
        modal.style.display = "none";

        dispatch(setMessage(null));
      }, 2000);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessage(err.response.data));
      } else {
        dispatch(
          setMessage({
            status: "error",
            message: err.response.status + " - " + err.response.statusText,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setMessage(null));
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setReassign(false);
  }, [selectedPackage]);

  return (
    <div
      className={`modal fade ${modal ? "show" : null}`}
      style={modal ? { display: "block" } : { display: "none" }}
      id="packageModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      onClick={() => setModal(false)}
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="packageModalTitle">
              Assign a Package to a Company
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="text-muted">Package: {selectedPackage.packageName}</p>
            <p className="text-muted mb-5">
              Price: {formatPrice(selectedPackage.price)}
            </p>
            <form className="needs-validation" onSubmit={addAdTypeForm}>
              <div className="form-row">
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="categoryId">Company</label>
                    <select
                      className="form-control"
                      id="category"
                      name="category"
                      onChange={(e) => setCompanyForm(e.target.value)}
                      value={companyForm}
                    >
                      {companies &&
                        companies.map((company) => (
                          <option key={company.companyName}>
                            {company.companyName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-row form-center-button">
                {!reassign && (
                  <button className="btn btn-primary" type="submit">
                    Assign
                  </button>
                )}
                {reassign && (
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      reassignCompany();
                      setReassign(false);
                    }}
                  >
                    Confirm Reassign Package
                  </button>
                )}
              </div>
            </form>
          </div>
          {message && (
            <div className="modal-footer">
              <div
                className={`alert ${
                  message.status === "success"
                    ? "alert-success"
                    : message.status === "warning"
                    ? "alert-warning"
                    : "alert-danger"
                } alert-dismissible fade show mt-3`}
                role="alert"
              >
                {message.message}
                <button
                  type="button"
                  className="close"
                  onClick={() => dispatch(setMessage(null))}
                >
                  <span>&times;</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
