import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./TopBar.css";
import EliteLogo from "../../assets/logos/elite-marketing.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setMessage } from "../../redux/actions";
import { formatDate } from "../../utils/formatDate";
import { getNotifications } from "../../redux/thunks";

export default function TopBar() {
  const user = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.notifications);
  const [isBarOpen, setIsBarOpen] = useState(true);
  const [isTopBarOpen, setIsTopBarOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(0);
  const setWindowSize = () => setWindowWidth(window.innerWidth);

  const [isNotificationUrgent, setIsNotificationUrgent] = useState(false);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", setWindowSize);
    return () => {
      window.removeEventListener("resize", setWindowSize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 768) {
      setIsTopBarOpen(false);
      document.querySelector("body").classList.remove("topbar-toggle-menu");
    }
  }, [windowWidth]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  useEffect(() => {
    let countIsUrgentFalse = 0;
    for (let index = 0; index < notifications.length; index++) {
      if (notifications[index].isUrgent) {
        setIsNotificationUrgent(true);
        break;
      } else {
        countIsUrgentFalse++;
      }
    }
    if (countIsUrgentFalse === notifications.length) {
      setIsNotificationUrgent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const toggleLeftSideBar = () => {
    document.querySelector("body").classList.toggle("toggle-menu");
    setIsBarOpen(!isBarOpen);
  };

  const toggleTopBar = () => {
    document.querySelector("body").classList.toggle("topbar-toggle-menu");
    setIsTopBarOpen(!isTopBarOpen);
  };

  return (
    <>
      <div className="topbar-mobile">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="mobile-logobar">
              <Link to="/user/dashboard" className="mobile-logo">
                <img
                  src={EliteLogo}
                  className="img-fluid"
                  alt="logo"
                  style={{ height: "2.7rem" }}
                />
              </Link>
            </div>
            <div className="mobile-togglebar">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <div className="topbar-toggle-icon">
                    <div
                      className="topbar-toggle-hamburger"
                      onClick={toggleTopBar}
                    >
                      <img
                        src="/orbiter-assets/images/svg-icon/horizontal.svg"
                        className="img-fluid menu-hamburger-horizontal"
                        style={{
                          display: `${isTopBarOpen ? "none" : "inline"}`,
                        }}
                        alt="horizontal"
                      />
                      <img
                        src="/orbiter-assets/images/svg-icon/verticle.svg"
                        className="img-fluid menu-hamburger-vertical"
                        style={{
                          display: `${!isTopBarOpen ? "none" : "inline"}`,
                        }}
                        alt="verticle"
                      />
                    </div>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="menubar">
                    <div className="menu-hamburger" onClick={toggleLeftSideBar}>
                      <img
                        src="/orbiter-assets/images/svg-icon/collapse.svg"
                        className="img-fluid menu-hamburger-collapse"
                        style={{
                          display: `${!isBarOpen ? "none" : "inline"}`,
                        }}
                        alt="collapse"
                      />
                      <img
                        src="/orbiter-assets/images/svg-icon/close.svg"
                        className="img-fluid menu-hamburger-close"
                        style={{
                          display: `${isBarOpen ? "none" : "inline"}`,
                        }}
                        alt="close"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="topbar">
        <div className="row align-items-center">
          <div className="col-md-12 align-self-center topbar-center">
            <div className="togglebar">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <div className="menubar">
                    <div className="menu-hamburger" onClick={toggleLeftSideBar}>
                      <img
                        src="/orbiter-assets/images/svg-icon/collapse.svg"
                        className="img-fluid menu-hamburger-collapse"
                        style={{
                          display: `${!isBarOpen ? "none" : "inline"}`,
                        }}
                        alt="collapse"
                      />
                      <img
                        src="/orbiter-assets/images/svg-icon/close.svg"
                        className="img-fluid menu-hamburger-close"
                        style={{
                          display: `${isBarOpen ? "none" : "inline"}`,
                        }}
                        alt="close"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="infobar">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-2">
                  <div className="notifybar">
                    <div className="dropdown">
                      <div
                        className={`dropdown-toggle infobar-icon ${
                          isNotificationUrgent ? "notification-urgent" : null
                        }`}
                        role="button"
                        id="notoficationlink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/orbiter-assets/images/svg-icon/notifications.svg"
                          className={`img-fluid ${
                            isNotificationUrgent
                              ? "notification-svg-urgent"
                              : null
                          }`}
                          alt="notifications"
                        />
                        {notifications.length ===
                        0 ? null : isNotificationUrgent ? (
                          <span className="live-icon live-icon-urgent"></span>
                        ) : (
                          <span className="live-icon"></span>
                        )}
                      </div>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="notoficationlink"
                      >
                        <div className={`notification-dropdown-title `}>
                          <h4
                            className={
                              isNotificationUrgent ? "text-danger" : null
                            }
                          >
                            Notifications
                          </h4>
                        </div>
                        <ul className="list-unstyled">
                          {notifications.length === 0 && (
                            <li className="media dropdown-item">
                              <div className="media-body">
                                <h5 className="action-title text-center">
                                  You don't have new notifications.
                                </h5>
                              </div>
                            </li>
                          )}
                          {notifications.length > 0 &&
                            notifications.slice(0, 5).map((notification) => (
                              <li
                                className="media dropdown-item"
                                key={notification.uuid}
                              >
                                {notification.isUrgent ? (
                                  <span className="action-icon badge badge-danger-inverse">
                                    <i className="fa fa-exclamation"></i>
                                  </span>
                                ) : (
                                  <span className="action-icon badge badge-warning-inverse">
                                    <i className="feather icon-package"></i>
                                  </span>
                                )}

                                <div className="media-body">
                                  <h5 className="action-title">
                                    {notification.message.length > 50
                                      ? notification.message.slice(0, 50) +
                                        "(...)"
                                      : notification.message}
                                  </h5>
                                  <p>
                                    <span className="timing">
                                      {formatDate(notification.createdAt)}
                                    </span>
                                  </p>
                                </div>
                              </li>
                            ))}
                          {notifications.length > 5 && (
                            <li className="media dropdown-item">
                              <div className="media-body">
                                <h5 className="action-title text-center">
                                  Go to notifications page to view all
                                </h5>
                              </div>
                            </li>
                          )}
                          <li className="media dropdown-item">
                            <div className="media-body notification-button">
                              <Link to="/user/notifications">
                                <button className="btn btn-outline-primary">
                                  View Notifications
                                </button>
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-inline-item">
                  <div className="profilebar">
                    <div className="dropdown">
                      <div
                        className="dropdown-toggle"
                        role="button"
                        id="profilelink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/orbiter-assets/images/users/profile.svg"
                          className="img-fluid"
                          alt="profile"
                        />
                        <span className="feather icon-chevron-down live-icon"></span>
                      </div>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="profilelink"
                      >
                        <div className="dropdown-item">
                          <div className="profilename">
                            <h5>{user.name}</h5>
                            <p>{user.role}</p>
                          </div>
                        </div>
                        <div className="userbox">
                          <ul className="list-unstyled mb-0">
                            <li className="media dropdown-item">
                              <Link
                                to={"/user/update-user/" + user.uuid}
                                className="profile-icon"
                                style={{ width: "max-content" }}
                              >
                                <img
                                  src="/orbiter-assets/images/svg-icon/user.svg"
                                  className="img-fluid"
                                  alt="user"
                                />
                                Update User Info
                              </Link>
                            </li>

                            <li className="media dropdown-item">
                              <div
                                className="profile-icon"
                                onClick={() => {
                                  dispatch(logoutUser());
                                  dispatch(setMessage(null));
                                  navigate("/login");
                                }}
                              >
                                <img
                                  src="/orbiter-assets/images/svg-icon/logout.svg"
                                  className="img-fluid"
                                  alt="logout"
                                />
                                Logout
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
